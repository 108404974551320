const convertLanguage = (lang, locale) => {
    if (lang && locale) {
      return lang + '-' + locale;
    }
    if (lang === 'it') return 'it-IT';
    else if (lang === 'en') return 'en-GB';
    else return lang;
  };
  
  export default convertLanguage;
  