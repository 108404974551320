import React from 'react';
import PropTypes from 'prop-types';
import { useScreenshot } from 'use-react-screenshot';
import { Button } from '@material-ui/core';
import Camera from 'images/assets/camera.svg';
import TuningSvgLoader from 'components/common/TuningSvgLoader';
import { useStore } from 'store/storeUtils.js';
import moment from 'moment';
import Capitalize from 'utils/Capitalize';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { observer } from 'mobx-react';

const TuningScreenshotButton = observer((props) => {
  const { screenshotClassName, screenshotRef, ...rest } = props;

  const [image, takeScreenshot] = useScreenshot();
  const [downloadScreen, setDownloadScreen] = React.useState();
  const store = useStore();
  const theme = useTheme();
  const matchesPhone = useMediaQuery(theme.breakpoints.down('xs'), {
    noSsr: true,
  });

  React.useEffect(() => {
    if (image && downloadScreen) {
      const projectName = Capitalize(store.loginProjectName);
      const date = moment(new Date()).format('DD/MM/YYYY');
      var a = document.createElement('a');
      a.href = image;
      a.download = projectName + '_' + date + '.png';
      
      a.click();
      setDownloadScreen(false);
    }
  }, [image, downloadScreen]);


  const getImage = () => {

    // Applico una classe che rimuove tutte le ombre dagli elementi
    // per un problema di compatibilità con la libreria che fa lo screenshot
    // della pagina
    //store.setOpenDrawer(!store.openDrawer);
    const toolbarContainer = document.querySelector('.navbar-container');
    const mainContainer = document.querySelector('.' + screenshotClassName);
    const pageContainer = document.querySelector(store.openDrawer ? '.body-wrapper-open' : '.body-wrapper-close');
    const bodytemp = document.body;
    const div = document.createElement("div");
    div.style.zIndex ="99999";
    div.style.width="100%";
    div.style.height="100%";
    div.style.position="fixed";
    div.style.padding="0";
    div.style.margin="0";
    div.style.top="0";
    div.style.left="0";
    div.style.down="0";
    div.style.right="0";

    if (mainContainer) {
      mainContainer.classList.add('no-shadow-4-screenshot');// Applico una classe che rimuove tutte le ombre dagli elementi
    }
    if (toolbarContainer) {
      toolbarContainer.classList.add('hide-component');
    }

    if (pageContainer) {
      pageContainer.classList.add('delete-margin');
    }
    bodytemp.prepend(div);
    setTimeout(() => {
      takeScreenshot(screenshotRef.current).then(() => {
        setDownloadScreen(true);

        // rimuovo la classe dopo aver scaricato lo screenshoot
        if (mainContainer) {
          mainContainer.classList.remove('no-shadow-4-screenshot');
        }

        if (toolbarContainer) {
          toolbarContainer.classList.remove('hide-component');
        }

        if (pageContainer) {
          pageContainer.classList.remove('delete-margin');
        }
        div.remove();
      });

    }, 2000);
   
  };

  return (
    <div className='screenshot' >
    <Button className='screenshot-button' onClick={getImage} disableRipple={true} {...rest}>
      <TuningSvgLoader data={Camera} alt='camera' style={!matchesPhone ? { paddingTop: '3px', paddingRight: '5px' } : { position: 'relative', top: '3px', right: '-1px' }} />
      {!matchesPhone && 'Screenshot'}
    </Button>
    </div>
  );
});

TuningScreenshotButton.propTypes = {
  /** className */
  screenshotClassName: PropTypes.string,
  /**reference of screenshot */
  screenshotRef: PropTypes.any,
};

export default TuningScreenshotButton;
