import React from 'react';
import TuningSnackbar from 'components/common/TuningSnackbar';
import TuningButton from 'components/common/TuningButton';
import * as serviceWorker from 'serviceWorker';
import { Trans } from '@tecma/i18n';
 import TuningLoader from "components/common/TuningLoader";

const ServiceWorkerWrapper = () => {
  const [showReload, setShowReload] = React.useState(false);
  const [waitingWorker, setWaitingWorker] = React.useState(null);
  const [showReloading, setShowReloading] = React.useState(false);

  const onSWUpdate = (registration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  React.useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = () => {
    delete localStorage['store_tuning'];
    if (waitingWorker) {
      waitingWorker.postMessage({ type: 'SKIP_WAITING' });
      waitingWorker.postMessage('SKIP_WAITING');
      setShowReloading(true);
    }
  };

  return (
    <TuningSnackbar
      open={showReload}
      style={{ color: 'white !important' }}
      message={!showReloading?
        <>
          <Trans
            i18nKey={'tuning.newVersion'}
            
          />
        </> : <><div style={{height: '60px'}}></div></>
      }
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      action={
        showReloading? 
        <TuningLoader loading={true} hasBackdrop={false} transparency /> :
        <TuningButton size='small' onClick={reloadPage}>
          <Trans i18nKey={'tuning.update'} defaults='Aggiorna' />
        </TuningButton>
      }
    />
  );
};

export default ServiceWorkerWrapper;
