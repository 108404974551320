import React from 'react';
import ChartConstants from 'constants/ChartConstants';
import {
  PieChart,
  Pie,
  ResponsiveContainer,
  Cell,
  Legend,
  Tooltip,
} from 'recharts';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Capitalize from 'utils/Capitalize';
import 'styles/charts.scss';
import format from 'utils/FormatThousand';
 import { toJS } from 'mobx';
import { Trans, useTranslation } from '@tecma/i18n';
/**
 * DoughnutChart
 * based on recharts
 *
 * @component
 * @see {@link http://recharts.org/en-US/api/PieChart PieChart API}
 */
const DoughnutChart = (props) => {
  const {
    availableStatuses,
    availableColors,
    data,
    height,
    width,
    radius,
    boolUseTranslation,
    
  } = props;

  const { t, i18n } = useTranslation()
 
  let percentuals = [];
  let ourData = toJS(data);
  let total = 0;
  ourData.forEach((dataElement) => {
    dataElement.data = +dataElement.data;
    total = total + dataElement.data;
  });

  ourData.forEach((dataElement) => {
    let dataPercent = Math.round((dataElement.data * 1000) / total) / 10;
    let label = dataElement.label;
    percentuals.push({ label, dataPercent });
  });

  const CustomTooltip = (dataTooltip) => {

    if (dataTooltip.active) {
      let percentual = percentuals.find(
        (perc) => perc.label === dataTooltip.payload[0].payload.label
      ).dataPercent;
      return (
        <div className='recharts-tooltip-wrapper'>
          <div className='recharts-default-tooltip custom-tooltip'>
            <p className='recharts-tooltip-label'>
              {`${t(`label.${Capitalize(dataTooltip.payload[0].payload.label)}`, {defaultValue:Capitalize(dataTooltip.payload[0].payload.label)})} : ${format(dataTooltip.payload[0].value, i18n.language)} - ${percentual}%`}</p>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer
      width='100%'
      height={height}
      className='doughnut-chart-container'
    >
      <PieChart width={width} height={height}>
        <Legend
          align='left'
          content={(p) => {
            p.payload.forEach((data) => {
              let myPerc = percentuals.find(
                (perc) => perc.label === data.payload.payload.label
              );
              data.payload.percent = myPerc.dataPercent;
            });
           
            return (

              <>
                <Grid container>
                  {p.payload.map((pay, i) => {
                      return(
                    <Grid key={'i' + i} item xs={12}>
                      <div className='legend-content'>
                        <div
                          className='donut-legend-icon'
                          style={{ backgroundColor: pay.color }}
                        ></div>
                        <div className='small-font-14'>
                          {boolUseTranslation ?  <Trans i18nKey={(`label.${pay.payload.label}`)} defaults={pay.payload.label} /> : Capitalize(pay.payload.label)}
                        </div>
                        <div className='small-font-14 donut-legend-stats'>
                          {`( ${format(pay.payload.value, i18n.language)} )`}
                          <div className='donut-legend-percentual-stats'>
                            {pay.payload.percent + '%'}
                          </div>
                        </div>
                      </div>
                    </Grid>
                 ) })}
                </Grid>
              </>
            );
          }}
        />
        <Tooltip
          content={<CustomTooltip />}
          allowEscapeViewBox={{ x: true, y: true }}
          wrapperStyle={{ top: -30, left: 5 }}
        />
        <Pie
          cx={'50%'}
          cy={height / 3}
          data={data}
          innerRadius={radius - 30}
          outerRadius={radius}
          dataKey='data'
        >
          {data.map((entry, index) => {
            const color = availableColors[index];
            return <Cell dataKey='data' key={`cell-${index}`} fill={color} />;
          })}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

DoughnutChart.propTypes = {
  /**
   * availableStatuses: all statuses to be displayed on chart and legend
   */
  availableStatuses: PropTypes.arrayOf(PropTypes.string),
  /**
   * availableColors: colors to use for each status
   */
  availableColors: PropTypes.arrayOf(PropTypes.string),
  /**
   * data
   */
  data: PropTypes.arrayOf(PropTypes.object),
  /**
   * width: of the chart
   */
  width: PropTypes.number,
  /**
   * height: of all the elements
   */
  height: PropTypes.number,
  /**
   * innerRadius: 0 is a pie chart. The higher it is, the bigger the "hole" at the center of the chart will be
   */
  // innerRadius: PropTypes.number,
  // /**
  //  * outerRadius: how "big" the chart is
  //  */
  // outerRadius: PropTypes.number,
  // /**
  //  * outerRadius: how "big" the chart is
  //  */
  radius: PropTypes.number,
  boolUseTranslation: PropTypes.bool,
  
};

DoughnutChart.defaultProps = {
  availableStatuses: ChartConstants.availableStatus,
  availableColors: ChartConstants.defaultColors,
  data: [],
  width: 100,
  height: 320,
  radius: 100,
  boolUseTranslation:true,
};

export default DoughnutChart;
