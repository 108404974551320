import React from 'react';
import PropTypes from 'prop-types';
import Header from 'components/common/TuningHeader';
import TuningSuspense from 'components/common/TuningSuspense';
import Navbar from 'components/common/TuningNavbar';
import { useStore } from 'store/storeUtils.js';
import { observer } from 'mobx-react';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

/**
 * Template generico della pagina
 * Contiene un Header, il contenuto della pagina circondato da un suspense
 * @component
 * @see TuningHeader
 * @see TuningSuspense
 */
const PageTemplate = observer((props) => {
  const { BodyComponent, title, goBack, setLanguage, ...rest } = props;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  const store = useStore();
  const { openDrawer } = store;
  const screenshotRef = React.createRef(null);

  return (
    <div  >
      <Header title={title} goBack={goBack} setLanguage={setLanguage} />
      <Navbar setLanguage={setLanguage} />
      <div
      ref={screenshotRef}
        className={
          typeof openDrawer === 'undefined'
            ? matches === true
              ? 'body-wrapper-close'
              : 'body-wrapper-open'
            : openDrawer === true
            ? 'body-wrapper-open'
            : 'body-wrapper-close'
        }
      >
        <div className='page-title'>{title}</div>
        <TuningSuspense>
          <BodyComponent title={title} screenshotRef={screenshotRef} {...rest} />
        </TuningSuspense>
      </div>
    </div>
  );
});

PageTemplate.propTypes = {
  /** BodyComponent: required content */
  BodyComponent: PropTypes.elementType.isRequired,
  /** title: i18n instance or string. TuningHeader prop */
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  /** goBack: boolean. TuningHeader prop */
  goBack: PropTypes.bool,

  setLanguage: PropTypes.func,
};

PageTemplate.defaultProps = {
  title: '',
  goBack: false,
};

export default PageTemplate;
