import React from "react";
import ReactDOM from "react-dom";
import App from "App";
import Store from "store/store.js";
import { StoreProvider } from "store/storeUtils.js";
import { create } from "mobx-persist";
import "styles/main.scss";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { CrmMUITheme } from "styles/crmMUITheme";
import { ApolloProvider } from "@apollo/client";
import API from "client/GraphqlClient";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorPage } from "@tecma/ui";
import { injectContextHeaders } from "@tecma/logs-fe";
import { logger } from "@tecma/logs-fe";

injectContextHeaders();

const theme = createMuiTheme(CrmMUITheme);

const hydrate = create({});

const store = new Store();
 hydrate('store_tuning', store)
  .catch((e) => {
    logger.error(e);
    delete localStorage["store_tuning"];
    const newStore = new Store();
    hydrate("store_tuning", newStore);
  })
  .finally(() => {
    document.title =
      store && store.pageTitles && store.pageTitles.tuning
        ? store.pageTitles.tuning
        : "Tuning";

    ReactDOM.render(
      <React.StrictMode>
        <StoreProvider value={store}>
          <ApolloProvider client={API(store, logger)}>
            <ErrorBoundary
              FallbackComponent={() => {
                return (
                  <ErrorPage
                    logo={
                      store.loginProjectName
                        ? `${process.env.REACT_APP_BUCKET_BASEURL}initiatives/${store.loginProjectName}/global/img/primary-logo.svg`
                        : undefined
                    }
                  />
                );
              }}
            >
              <ThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <App />
                </MuiPickersUtilsProvider>
              </ThemeProvider>
            </ErrorBoundary>
          </ApolloProvider>
        </StoreProvider>
      </React.StrictMode>,
      document.getElementById('root')
    );
  });
