import React from "react";
import RouteConsts from "routes/Routes.jsx";
import { useHistory } from "react-router-dom";
import { useStore } from "store/storeUtils.js";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import MenuIcon from "@material-ui/icons/Menu";
import ListItem from "@material-ui/core/ListItem";
import { observer } from "mobx-react";
import { autorun } from "mobx";
import TuningSvgLoader from "components/common/TuningSvgLoader";
import LogoTecma from "images/assets/tecma_logo.svg";
import "styles/navbar.scss";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { useTranslation } from "@tecma/i18n";
import getOrderedLanguages from "utils/getOrderedLanguage";
import convertLanguage from "utils/convertLanguage";

/**
 * Sidebar
 * based on material-ui BreadCrumbs
 * It contains a mobile drawer
 * @component
 * @see {@link https://material-ui.com/api/drawer/  Drawer API}
 */

const TuningNavbar = observer((props) => {
  const { setLanguage } = props;

  const history = useHistory();
  const store = useStore();
  const { t } = useTranslation();

  const [orderedLanguages, setOrderedLanguages] = React.useState(
    getOrderedLanguages(
      store.configLanguages,
      convertLanguage(store.loggedUser.language, store.loggedUser.locale)
    )
  );

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"), { noSsr: true });
  const matchesPhone = useMediaQuery(theme.breakpoints.down("xs"), {
    noSsr: true,
  });

  const navbarRoutes = RouteConsts && RouteConsts.filter((e) => e.navbar);
  const addDefaultSrc = (ev, url) => {
    if (ev.target.src.includes(process.env.REACT_APP_BUCKET_BASEURL)) {
      ev.target.src = url && url.length > 0 && url[0];
    } else {
      ev.target.hidden = true;
    }
  };
  const handleClick = () => {
    store.toggleMenuMobile();
    store.setOpenDrawer(!store.openDrawer);
  };

  const handleClose = (val) => {
    store.closeMenuMobile();
    if (typeof val !== "undefined" && typeof val === "number") {
      goTo(val);
    }
    if (matchesPhone) {
      store.setOpenDrawer(!store.openDrawer);
    }
  };

  const goHome = () => {
    store.closeMenuMobile();
    goTo(0);
  };

  const goTo = (val) => {
    store.updateCurrentNavTab(val, navbarRoutes[val].to);
    history.push(navbarRoutes[val].to);
  };

  const handleChangeLanguage = (selectedLanguage) => {
    setOrderedLanguages(
      getOrderedLanguages(store.configLanguages, selectedLanguage)
    );
    const trueLanguage = selectedLanguage.split("-")[0];
    const locale = selectedLanguage.split("-")[1];
    setLanguage(trueLanguage, locale);
    store.setOpenDrawer(!store.openDrawer);
  };

  if (typeof store.openDrawer === "undefined") {
    if (matches) {
      store.setOpenDrawer(false);
    } else {
      store.setOpenDrawer(true);
    }
  }

  React.useEffect(
    () =>
      autorun(() => {
        if (
          typeof store.realLocation === "undefined" &&
          (store.currentNavTab || store.currentNavTab === 0) &&
          navbarRoutes[store.currentNavTab] &&
          history.location.pathname !== navbarRoutes[store.currentNavTab].to
        ) {
          history.push(navbarRoutes[store.currentNavTab].to);
        }
      }),
    []
  );
  const drawer = (
    <>
      {store.openDrawer || matchesPhone ? (
        <img
          className={matchesPhone ? "image-drawer-mobile" : "image-drawer"}
          alt="logo"
          src={`${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${store.loginProjectName}/global/img/primary-logo.svg`}
          onClick={goHome}
          style={{ cursor: "pointer" }}
        />
      ) : (
        <img
          className="image-drawer-small"
          alt="logo-small"
          src={`${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${store.loginProjectName}/global/img/primary-logo--XS.svg`}
          onError={(ev) => addDefaultSrc(ev, store.logo)}
          onClick={goHome}
          style={{ cursor: "pointer" }}
        />
      )}
      <List
        className={store.openDrawer ? "navbar-list-open" : "navbar-list-close"}
      >
        {navbarRoutes &&
          navbarRoutes.map((tabEl, i) => (
            <ListItem
              className={i === 0 ? "first-drawer-of-list" : ""}
              key={`tab${tabEl.order}`}
              onClick={() => handleClose(i)}
              selected={store.currentNavTab === i}
            >
              <Button
                className="drawer-button semi-bold"
                disableRipple={true}
                startIcon={
                  <TuningSvgLoader
                    alt=""
                    data={tabEl.icon}
                    className="navbar-image-size"
                  />
                }
                classes={{
                  label: "justify-left",
                }}
              >
                {store.openDrawer && tabEl.navbarTitle}
              </Button>
            </ListItem>
          ))}
      </List>
      {store.openDrawer && !matchesPhone && (
        <div className="image-tecm-container">
          <div style={{ fontSize: "11px", paddingBottom: "8px" }}>
            Powered by
          </div>
          <TuningSvgLoader
            data={LogoTecma}
            alt="Tecma"
            className="image-tecma"
          />
          <div style={{ fontSize: "11px" }}>version 1.0.1</div>
        </div>
      )}
      {matchesPhone && (
        <div>
          <ToggleButtonGroup className="language-buttons">
            {orderedLanguages.map((language, index) => {
              const trueLanguage = language ? language.split("-")[0] : language;
              const locale = language ? language.split("-")[1] : null;
              return (
                <ToggleButton
                  key={index}
                  value={language.toUpperCase()}
                  onClick={() => {
                    handleChangeLanguage(language);
                  }}
                  selected={
                    store.loggedUser.language === trueLanguage &&
                    store.loggedUser.locale === locale
                  }
                  style={{
                    borderRight:
                      index === orderedLanguages.length - 1
                        ? "0px"
                        : "2px solid #CACBD3",
                  }}
                >
                  {language.toUpperCase()}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </div>
      )}
    </>
  );
  return (
    <>
      <div className="navbar-container">
        <Drawer
          variant={matchesPhone ? "persistent" : "permanent"}
          anchor={matchesPhone ? "right" : "left"}
          open={store.openDrawer}
          classes={
            store.openDrawer
              ? {
                  paperAnchorLeft: "permanent-drawer",
                  paperAnchorRight: "permanent-drawer-mobile",
                }
              : {
                  paper: "permanent-drawer-close",
                }
          }
        >
          {!matchesPhone && (
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleClick}
              className="hamburger-button"
              disableRipple={true}
            >
              <MenuIcon />
            </IconButton>
          )}
          {drawer}
        </Drawer>
      </div>
    </>
  );
});

TuningNavbar.propTypes = {
  setLanguage: PropTypes.func,
};

export default TuningNavbar;
