/**
 * validates an email i.e. checks if the given string is compliant to an email format (x@x.x)
 * @param {string} email email to validate
 */

const r1 = /^\w(.)*@\w(.)+(\.\w{2,3})+$/;

 const ValidateEmail = (email) => {
    return email && r1.test(String(email).toLowerCase());
}

export default ValidateEmail;