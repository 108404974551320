import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@tecma/i18n';

/**
 * react suspense wrapper with default fallback
 * @component
 */
const TuningSuspense = ({ children }) => {
  return (
    <React.Suspense
      fallback={
        <div>
          <Trans i18nKey={'tuning.loading'} />
        </div>
      }
    >
      {children}
    </React.Suspense>
  );
};

/** prop types */
TuningSuspense.propTypes = {
  /** children: required */
  children: PropTypes.node.isRequired,
};

export default TuningSuspense;
