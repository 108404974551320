import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import TuningAvatar from 'components/common/TuningAvatar';
import { useStore } from 'store/storeUtils';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { useTranslation } from '@tecma/i18n';
import 'styles/header.scss';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import LogoTecma from 'images/assets/logo_TECMA_white.svg';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import TuningSvgLoader from './TuningSvgLoader';
import convertLanguage from 'utils/convertLanguage';
import getOrderedLanguages from 'utils/getOrderedLanguage';

/**
 * Page Header
 * based on material-ui BreadCrumbs
 * Unknown props are simply ignored
 * @component
 * @see {@link https://material-ui.com/api/breadcrumbs/  BreadCrumbs API}
 */
const TuningHeader = observer((props) => {
  const {
    setLanguage,
  } = props;

  const { t } = useTranslation();

  const store = useStore();

  const [orderedLanguages, setOrderedLanguages] = React.useState(getOrderedLanguages(store.configLanguages, convertLanguage(store.loggedUser.language, store.loggedUser.locale)));
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openDrawer = () => {
    store.setOpenDrawer(!store.openDrawer);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeLanguage = (selectedLanguage) => {
    setOrderedLanguages(getOrderedLanguages(store.configLanguages, selectedLanguage));
    const trueLanguage = selectedLanguage.split('-')[0];
    const locale = selectedLanguage.split('-')[1];
    setLanguage(trueLanguage, locale);
    handleClose();
  };

  const theme = useTheme();
  const matchesPhone = useMediaQuery(theme.breakpoints.down('xs'), {
    noSsr: true,
  });

  return (
    <div className='header-wrapper'>
      <div className='tecma-elements'>
        <div className='image-tecma-wrapper'>
          <TuningSvgLoader
            data={LogoTecma}
            alt='Tecma'
            className='image-tecma'
          />
        </div>
        {!matchesPhone && <div className='tuning-wrapper'>TUNING</div>}
      </div>
      <div className='desktop-elements'>
        <TuningAvatar />
        {!matchesPhone ? (
          <div>
            <Button
              aria-controls='menu'
              className='language-selector'
              onClick={handleClick}
              disableRipple={true}
            >
              {t('language.current')}
              <KeyboardArrowDownIcon style={{ cursor: 'pointer' }} />
            </Button>
            <Menu
              id='menu'
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              classes={{
                paper: 'no-border corner-positioning language-option-selection',
              }}
            >
              {orderedLanguages.map((language, index) => {
                const trueLanguage = language ? language.split('-')[0] : language;
                const locale = language ? language.split('-')[1] : null;
                return (
                  <MenuItem
                    key={'languages-' + language + index}
                    onClick={() => {
                      handleChangeLanguage(language);
                    }}
                    selected={store.loggedUser.language === trueLanguage && store.loggedUser.locale === locale}
                  >
                    {t('switch.' + language, {
                      defaultValue: language.toUpperCase(),
                    })}
                  </MenuItem>
                );
              })}
            </Menu>
          </div>
        ) : (
          <IconButton
            aria-label='more'
            aria-controls='long-menu'
            aria-haspopup='true'
            onClick={openDrawer}
            className='mobile-drawer'
          >
            <MenuIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
});

TuningHeader.propTypes = {
  /** title: i18n instance or string. TuningHeader prop */
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  /** goBack: boolean. If true, current page is a subPath. TODO: change this prop name */
  goBack: PropTypes.bool,
  setLanguage: PropTypes.func,
};

TuningHeader.defaultProps = {
  title: '',
  goBack: false,
};

export default TuningHeader;
