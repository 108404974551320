import React from 'react';
import Capitalize from 'utils/Capitalize';
import moment from 'moment';
import { Trans } from '@tecma/i18n';
import { isEqual } from "lodash";
import { SOURCE_DIVIDER } from "constants/GenericConstants";
import { DatesFormatter } from "./datesFormatter";
const checkDates = (dates1, dates2) => {
  const dates1Array = Array.from(dates1).sort();
  const dates2Array = Array.from(dates2).sort();
  return isEqual(dates1Array, dates2Array);
};

const shouldAggregateChannelGroup = (channelGroup) => {
  // inserire nell'array tutti i channel che devono essere SEMPRE aggregati, a prescindere dal source
  return ["Organic Search"].includes(channelGroup);
}

const formatDate = (data) => {
  let newDate = "";
  if (!data.includes("-"))
    newDate = data.replace(/(\d{4})(\d{2})(\d{2})/, "$2-$3-$1");
  else newDate = data.replace(/(\d{4})-(\d{2})-(\d{2})/, "$2-$3-$1");
  return newDate;
};

const orderDate = (listArray) => {
  listArray.sort((first, second) => {
    const a_date = new Date(second);
    const b_date = new Date(first);
    return a_date - b_date;
  });
};

const computeSourceMap = (source, el, keys, noConcatenation) => {
  const [channelKey, sourceKey] = keys;
  // se dati nel "vecchio" formato, uso solo ga:channelGrouping per calcolare la key
  let gaKey = "".concat(el.dimensions[channelKey]);
  // se dati nel "nuovo" formato, concateniamo ga:channelGrouping e ga:source per calcolare la key, 
  // tranne per le eccezioni dentro shouldAggregateChannelGroup
  if (!noConcatenation && el.dimensions[sourceKey] && !shouldAggregateChannelGroup(el.dimensions[channelKey])) {
    gaKey = gaKey.concat(SOURCE_DIVIDER).concat(el.dimensions[sourceKey]);
  }
  const newVal = parseInt(el.metrics[0].values[0]);
  // creiamo o aggiorniamo il valore nella mappa
  source[gaKey] =
    source[gaKey] === undefined
      ? newVal
      : parseInt(source[gaKey]) + newVal;
  return source;
}


const googleAnalyticsViewFormat = (response) => {
  const googleMap = {};
  const pieGraphDevice = [];
  const pieGraphUsers = [];
  var barGraphUsr = [];
  const lineGraphDailyUsr = [];
  const tableData = [];
  googleMap['totalUsr'] = {
    text: <Trans i18nKey={'google.totalUsers'} />,
    dataNumber: 0,
    type: "number",
    period: "",
  };
  googleMap['sessions'] = {
    text: (
      <Trans i18nKey={'google.totalSession' } />
    ),
    dataNumber: 0,
    type: "number",
    period: "",
  };
  googleMap['avgSession'] = {
    text: (
      <Trans i18nKey={
         'google.averageSessionTime'
      }
      />
    ),
    dataNumber: 0,
    type: "time",
    period: "",
  };
  googleMap['pageView'] = {
    text: <Trans i18nKey={'google.pageView'}/>,
    dataNumber: 0,
    type: "number",
    period: "",
  };
  googleMap['avgTimePage'] = {
    text: (
      <Trans i18nKey={'google.averagePageTime'} />
    ),
    dataNumber: 0,
    type: "time",
    period: "",
  };
  googleMap['pageviewsPerSession'] = {
    text: (
      <Trans i18nKey={'google.pagesPerSession'} />
    ),
    dataNumber: 0,
    type: "number",
    period: "",
  };
  googleMap['bounceRate'] = {
    text: <Trans i18nKey={'google.bounceRate' } />,
    dataNumber: 0,
    type: "percent",
    period: "",
  };
  googleMap['percentNewSessions'] = {
    text: (
      <Trans i18nKey={'google.newSessions' } />
    ),
    dataNumber: 0,
    type: "percent",
    period: "",
  };
  googleMap['usrSessions'] = {
    text: (
      <Trans i18nKey={'google.sessionsPerUser'} />
    ),
    dataNumber: 0,
    type: "number",
    period: "",
  };
  if (response && response.length > 0) {
    const dataParams = response[0].data.rows;
    const dataDailyUsers = response[0].data.rows;
    const dataUsersType = response[1].data.rows;
    const dataUsersDevice = response[2].data.rows;
    const dataUsersSource = response[3].data.rows;
    const dataUsersSource2 = response[5]?.data.rows;

    const dataUsersDates = new Set();
    const dataUsersDates2 = new Set();

    if (dataUsersSource) {
      dataUsersSource.map((elem) => {
        dataUsersDates.add(elem.dimensions[0]);
      });
    }
    if (dataUsersSource2) {
      dataUsersSource2.map((elem) => {
        dataUsersDates2.add(elem.dimensions[0]);
      });
    }

    const device = {};
    const typeUsr = {};
    const source = {};
    const landing = {};
    if (dataUsersDevice && dataUsersDevice.length > 0)
      dataUsersDevice.map((elem) => {
        device[elem.dimensions[1]] =
          device[elem.dimensions[1]] === undefined
            ? parseInt(elem.metrics[0].values[0])
            : parseInt(device[elem.dimensions[1]]) +
              parseInt(elem.metrics[0].values[0]);
      });
    if (dataUsersType && dataUsersType.length > 0)
      dataUsersType.map((elem) => {
        typeUsr[elem.dimensions[1]] =
          typeUsr[elem.dimensions[1]] === undefined
            ? parseInt(elem.metrics[0].values[0])
            : parseInt(typeUsr[elem.dimensions[1]]) +
              parseInt(elem.metrics[0].values[0]);
      });

    if (
      dataUsersSource2 &&
      dataUsersSource2.length > 0 &&
      checkDates(dataUsersDates, dataUsersDates2)
    ) {
      dataUsersSource2.map((el) => computeSourceMap(source, el, [1, 2]));
    } else if (dataUsersSource && dataUsersSource.length > 0) {
      dataUsersSource.map((el) => computeSourceMap(source, el, [1, 2], true));
    }

    if (dataDailyUsers && dataDailyUsers.length > 0)
      dataDailyUsers.map((elem) => {
        //line graph dayly users
        lineGraphDailyUsr.push({
          name: formatDate(elem.dimensions[0]),
          utenti: parseInt(elem.metrics[0].values[0]),
        });
      });

    orderDate(lineGraphDailyUsr);

    lineGraphDailyUsr.forEach((el) => {
      el.name = DatesFormatter(new Date(el.name), {
        day: "2-digit",
        month: "2-digit",
      });
    });

    if (dataParams && dataParams.length > 0) {
      dataParams.map((elem) => {
        googleMap.totalUsr.dataNumber += parseInt(elem.metrics[0].values[0]);
        googleMap.sessions.dataNumber += parseInt(elem.metrics[0].values[1]);
        googleMap.avgSession.dataNumber += parseInt(elem.metrics[0].values[2]);
        googleMap.pageView.dataNumber += parseInt(elem.metrics[0].values[3]);
        googleMap.avgTimePage.dataNumber += parseInt(elem.metrics[0].values[4]);
        googleMap.bounceRate.dataNumber += parseInt(elem.metrics[0].values[6]);
        googleMap.percentNewSessions.dataNumber += parseInt(
          elem.metrics[0].values[7]
        );
      });
      googleMap.usrSessions.dataNumber =
      googleMap.sessions.dataNumber / googleMap.totalUsr.dataNumber;
      googleMap.pageviewsPerSession.dataNumber =
      googleMap.pageView.dataNumber / googleMap.sessions.dataNumber;
      googleMap.avgSession.dataNumber =
      googleMap.avgSession.dataNumber / parseInt(dataParams.length);
      googleMap.avgTimePage.dataNumber =
      googleMap.avgTimePage.dataNumber /
      parseInt(dataParams.length);
      googleMap.bounceRate.dataNumber =
      parseInt(googleMap.bounceRate.dataNumber) / parseInt(dataParams.length);
      googleMap.percentNewSessions.dataNumber =
      parseInt(googleMap.percentNewSessions.dataNumber) /
      parseInt(dataParams.length);
      googleMap.avgSession.dataNumber = googleMap.avgSession.dataNumber / 10;
      googleMap.avgTimePage.dataNumber = googleMap.avgTimePage.dataNumber / 10;
    }
    for (const [key, val] of Object.entries(device))
      pieGraphDevice.push({ data: val, label: key });

    for (const [key, val] of Object.entries(typeUsr))
      pieGraphUsers.push({ data: val, label: key });

    for (const [key, val] of Object.entries(source))
      barGraphUsr.push([{ data: val, label: key }]);

    for (const [key, val] of Object.entries(landing)) {
      tableData.push({
        landing: Capitalize(
          key.replace("/", "").replace(".html", "").replace("-", " ")
        ),
        usrs: val[0],
        bounce: Math.round(val[1] * 100) / 100,
        view: key,
      });
    }
  }
  googleMap["daviceChart"] = pieGraphDevice;
  googleMap["userChart"] = pieGraphUsers;
  googleMap["sourceChart"] = barGraphUsr;
  googleMap["dailyChartChart"] = lineGraphDailyUsr;
  googleMap["landingTable"] = tableData;

  return googleMap;
};

const googleAnalyticsFormat = (response) => {
  const googleMap = {};
  const pieGraphDevice = [];
  const pieGraphUsers = [];
  const barGraphUsr = [];
  const lineGraphDailyUsr = [];
  const tableData = [];
  googleMap['sessions'] = {
    text: (
      <Trans i18nKey={'google.totalSession' } />
    ),
    dataNumber: 0,
    type: "number",
    period: "",
  };
  googleMap['avgSession'] = {
    text: (
      <Trans i18nKey={
         'google.averageSessionTime'
      }
      />
    ),
    dataNumber: 0,
    type: "time",
    period: "",
  };
  googleMap['pageView'] = {
    text: <Trans i18nKey={'google.pageView'}/>,
    dataNumber: 0,
    type: "number",
    period: "",
  };
  googleMap['avgTimePage'] = {
    text: (
      <Trans i18nKey={'google.averagePageTime' } />
    ),
    dataNumber: 0,
    type: "time",
    period: "",
  };
  googleMap['pageviewsPerSession'] = {
    text: (
      <Trans i18nKey={'google.pagesPerSession' } />
    ),
    dataNumber: 0,
    type: "number",
    period: "",
  };
  googleMap['bounceRate'] = {
    text: <Trans i18nKey={'google.bounceRate' } />,
    dataNumber: 0,
    type: "percent",
    period: "",
  };
  googleMap['percentNewSessions'] = {
    text: (
      <Trans i18nKey={'google.newSessions' } />
    ),
    dataNumber: 0,
    type: "percent",
    period: "",
  };
  googleMap['usrSessions'] = {
    text: (
      <Trans i18nKey={'google.sessionsPerUser' } />
    ),
    dataNumber: 0,
    type: "number",
    period: "",
  };
  if (response) {
    const dataUsers =
      response[0] && response[0].data ? response[0].data.rows : null;
    const dataUsers2 =
      response[3] && response[3].data ? response[3].data.rows : null;

    const dataUsersDates = new Set();
    const dataUsersDates2 = new Set();

    if (dataUsers) {
      dataUsers.map((elem) => {
        dataUsersDates.add(elem.dimensions[0]);
      });
    }
    if (dataUsers2) {
      dataUsers2.map((elem) => {
        dataUsersDates2.add(elem.dimensions[0]);
      });
    }

    const dataParams =
      response[1] && response[1].data ? response[1].data.rows : null;
    const dataDailyUsers =
      response[0] && response[0].data ? response[0].data.rows : null;
    const device = {};
    const typeUsr = {};
    const source = {};
    const landing = {};
    const baseUrl =
      response[2] &&
      response[2].data &&
      response[2].data.rows &&
      response[2].data.rows.length > 0
        ? response[2].data.rows[0].dimensions[2].replace("www.", "https://")
        : "https://localhost/";
    var usrNumber = 0;
    var userSession = 0;
    var totalRealUsers = 0;

    if (
      dataUsers2 &&
      dataUsers2.length > 0 &&
      checkDates(dataUsersDates, dataUsersDates2)
    ) {
      dataUsers2.map((el) => computeSourceMap(source, el, [3, 5]));
    } else if (dataUsers && dataUsers.length > 0) {
      dataUsers.map((el) => computeSourceMap(source, el, [3, 5], true));
    }
    if (dataUsers && dataUsers.length > 0) {
      dataUsers.map((elem) => {
        device[elem.dimensions[1]] =
          device[elem.dimensions[1]] === undefined
            ? parseInt(elem.metrics[0].values[0])
            : parseInt(device[elem.dimensions[1]]) +
              parseInt(elem.metrics[0].values[0]);

        typeUsr[elem.dimensions[2]] =
          typeUsr[elem.dimensions[2]] === undefined
            ? parseInt(elem.metrics[0].values[0])
            : parseInt(typeUsr[elem.dimensions[2]]) +
              parseInt(elem.metrics[0].values[0]);
      });
    }

    if (dataDailyUsers && dataDailyUsers.length > 0)
      dataDailyUsers.map((elem) => {
        usrNumber += parseInt(elem.metrics[0].values[0]);
        //filtrando utenti per data
        lineGraphDailyUsr.push({
          name: formatDate(elem.dimensions[0]),
          utenti: parseInt(elem.metrics[0].values[0]),
          landing: elem.dimensions[1],
        });
      });
    orderDate(lineGraphDailyUsr);

    lineGraphDailyUsr.forEach((el) => {
      el.name = DatesFormatter(new Date(el.name), {
        day: "2-digit",
        month: "2-digit",
      });
    });

    var landingVal = {};
    if (dataParams && dataParams.length > 0) {
      dataParams.map((elem) => {
        googleMap.sessions.dataNumber += parseInt(elem.metrics[0].values[0]);
        googleMap.avgSession.dataNumber += parseInt(elem.metrics[0].values[1]);
        googleMap.pageView.dataNumber += parseInt(elem.metrics[0].values[2]);
        googleMap.avgTimePage.dataNumber += parseInt(elem.metrics[0].values[3]);
        googleMap.bounceRate.dataNumber += parseInt(elem.metrics[0].values[5]);
        googleMap.percentNewSessions.dataNumber += parseInt(
          elem.metrics[0].values[6]
        );

        if (response[1].columnHeader.dimensions[1].includes("pagePath")) {
          if (!landingVal[elem.dimensions[1]])
            landingVal[elem.dimensions[1]] = 0;
          if (!landing[elem.dimensions[1]])
            landing[elem.dimensions[1]] = [0, 0];
          landing[elem.dimensions[1]][0] += parseInt(elem.metrics[0].values[7]);
          landing[elem.dimensions[1]][1] += parseInt(elem.metrics[0].values[5]);
          landingVal[elem.dimensions[1]]++;
          totalRealUsers += parseInt(elem.metrics[0].values[7]);
        }
      });
      
      googleMap.pageviewsPerSession.dataNumber =
        googleMap.pageView.dataNumber / googleMap.sessions.dataNumber;
      googleMap.avgSession.dataNumber =
        googleMap.avgSession.dataNumber / parseInt(dataParams.length);
      googleMap.avgTimePage.dataNumber =
        googleMap.avgTimePage.dataNumber /
        parseInt(dataParams.length);
      googleMap.bounceRate.dataNumber =
        parseInt(googleMap.bounceRate.dataNumber) / parseInt(dataParams.length);
      googleMap.percentNewSessions.dataNumber =
        parseInt(googleMap.percentNewSessions.dataNumber) /
        parseInt(dataParams.length);
      userSession = googleMap.sessions.dataNumber / totalRealUsers;
      googleMap.avgSession.dataNumber = googleMap.avgSession.dataNumber / 10;
      googleMap.avgTimePage.dataNumber = googleMap.avgTimePage.dataNumber / 10;
    }

    for (const [key, val] of Object.entries(device))
      pieGraphDevice.push({ data: val, label: key });

    for (const [key, val] of Object.entries(typeUsr))
      pieGraphUsers.push({ data: val, label: key });

    for (const [key, val] of Object.entries(source))
      barGraphUsr.push([{ data: val, label: key }]);

    for (const [key, val] of Object.entries(landing)) {
      const bounce = val[1] / landingVal[key];
      tableData.push({
        landing: Capitalize(
          key.replace("/", "").replace(".html", "").replace("-", " ")
        ),
        usrs: val[0],
        bounce: Math.round(bounce * 100) / 100,
        view: baseUrl + key,
      });
    }
    googleMap.usrSessions.dataNumber = userSession;
  }
  googleMap['totalUsr'] = {
    text: <Trans i18nKey={'google.totalUsers' } />,
    dataNumber: usrNumber,
    type: "number",
    period: "",
  };
  googleMap["daviceChart"] = pieGraphDevice;
  googleMap["userChart"] = pieGraphUsers;
  googleMap["sourceChart"] = barGraphUsr;
  googleMap["dailyChartChart"] = lineGraphDailyUsr;
  googleMap["landingTable"] = tableData;

  return googleMap;
};

const googleAdsFormat = (response) => {
  const googleAdsMap = {};
  if (response) {
    const dataAds =
      response[4] && response[4].data ? response[4].data.rows : null;
    const impression = {};
    const click = {};
    const clickTime = {};
    const pieGraphAds = [];
    const barGraphAds = [];
    const lineGraphAds = [];
    var totalClick = 0;
    var totalImpresison = 0;
    if (dataAds && dataAds.length > 0)
      dataAds.map((elem) => {
        impression[elem.dimensions[1]] =
          impression[elem.dimensions[1]] === undefined
            ? parseInt(elem.metrics[0].values[1])
            : parseInt(impression[elem.dimensions[1]]) +
              parseInt(elem.metrics[0].values[1]);

        click[elem.dimensions[1]] =
          click[elem.dimensions[1]] === undefined
            ? parseInt(elem.metrics[0].values[0])
            : parseInt(click[elem.dimensions[1]]) +
              parseInt(elem.metrics[0].values[0]);

        clickTime[formatDate(elem.dimensions[0])] =
          clickTime[formatDate(elem.dimensions[0])] === undefined
            ? parseInt(elem.metrics[0].values[0])
            : parseInt(clickTime[formatDate(elem.dimensions[0])]) +
              parseInt(elem.metrics[0].values[0]);

        totalClick += parseInt(elem.metrics[0].values[0]);
        totalImpresison += parseInt(elem.metrics[0].values[1]);
      });
    for (const [key, val] of Object.entries(impression))
      pieGraphAds.push({ data: val, label: key });

    for (const [key, val] of Object.entries(clickTime))
      lineGraphAds.push({ name: key, click: val });

    for (const [key, val] of Object.entries(click))
      barGraphAds.push([{ data: val, label: key }]);

    orderDate(lineGraphAds);

    lineGraphAds.forEach((el) => {
      el.name = DatesFormatter(new Date(el.name), {
        day: "2-digit",
        month: "2-digit",
      });
    });

    googleAdsMap["deviceImpressions"] = pieGraphAds;
    googleAdsMap["deviceClicks"] = barGraphAds;
    googleAdsMap["deviceClickDate"] = lineGraphAds;
    googleAdsMap["allClick"] = totalClick;
    googleAdsMap["allImpression"] = totalImpresison;
  }
  return googleAdsMap;
};

const appartamentTypeFormat = (appType) => {
  var verticalGraphData = [{ label: "none", locali: 0 }];
  if (appType && appType.data) {
    verticalGraphData = [];
    const type = appType.data.getAllTypologies;
    type
      .filter((locale) => locale.visits > 0)
      .map((elem) => {
        verticalGraphData.push({
          label: elem.name,
          locali: parseInt(elem.visits),
        });
      });
  }
  return verticalGraphData;
};

const appartamentPlanFormat = (appPlan) => {
  const tableData = [];
  if (appPlan && appPlan.data) {
    const plan = appPlan.data.getAllPlans;
    var incr = 0;
    var orderPlan = plan.slice().sort((a, b) => b.visits - a.visits);
    orderPlan.map((elem) => {
      incr++;
      tableData.push({
        position: incr,
        unit: elem.name,
        taglio: elem.typology ? elem.typology.name : "",
        visit: parseInt(elem.visits),
      });
    });
  }
  return tableData;
};

const facebookAdsFormat = (response) => {
  const facebookMap = {};
  const lineGraphAds = [];
  const barGraphAds = [];
  const barAgeGraph = [];
  const pieChart = [];
  if (response) {
    const date = {};
    const deviceClk = {};
    const age = {};
    const deviceImp = {};
    var totalClick = 0;
    var totalImpression = 0;
    if (response.impressionsDataAge && response.impressionsDataDevice) {
      const impressionArrayAge = response.impressionsDataAge;
      impressionArrayAge.map((elem) => {
        age[elem.age] =
          age[elem.age] === undefined
            ? parseInt(elem.impressions)
            : parseInt(age[elem.age]) + parseInt(elem.impressions);
      });
      const impressionArrayDevice = response.impressionsDataDevice;
      impressionArrayDevice.map((elem) => {
        deviceImp[elem.device_platform] =
          deviceImp[elem.device_platform] === undefined
            ? parseInt(elem.impressions)
            : parseInt(deviceImp[elem.device_platform]) +
              parseInt(elem.impressions);
      });
      impressionArrayDevice.map((elem) => {
        totalImpression =
          parseInt(totalImpression) + parseInt(elem.impressions);
      });
      for (const [key, val] of Object.entries(age))
        barAgeGraph.push({ label: key, age: val });

      for (const [key, val] of Object.entries(deviceImp))
        pieChart.push({ data: val, label: key });
    } else if (response.length) {
      const clickArray = response;
      clickArray.map((elem) => {
        deviceClk[elem.device_platform] =
          deviceClk[elem.device_platform] === undefined
            ? parseInt(elem.clicks)
            : parseInt(deviceClk[elem.device_platform]) + parseInt(elem.clicks);

        date[formatDate(elem.date_start)] =
          date[formatDate(elem.date_start)] === undefined
            ? parseInt(elem.clicks)
            : parseInt(date[formatDate(elem.date_start)]) +
              parseInt(elem.clicks);

        totalClick = parseInt(totalClick) + parseInt(elem.clicks);
      });
      for (const [key, val] of Object.entries(date))
        lineGraphAds.push({ name: key, click: val });

      for (const [key, val] of Object.entries(deviceClk))
        barGraphAds.push([{ data: val, label: key }]);
    }

    orderDate(lineGraphAds);

    lineGraphAds.forEach((el) => {
      el.name = DatesFormatter(new Date(el.name), {
        day: "2-digit",
        month: "2-digit",
      });
    });

    facebookMap["deviceImpressions"] = pieChart;
    facebookMap["ageImpression"] = barAgeGraph;
    facebookMap["deviceClicks"] = barGraphAds;
    facebookMap["lineDataClick"] = lineGraphAds;
    facebookMap["allClick"] = totalClick;
    facebookMap["allImpression"] = totalImpression;
  }
  return facebookMap;
};

function filterDates(data, type, start, end) {
  var newRawData = [];
  //end define pivot period
  const format = "YYYY-MM-DD";
  end = end.endOf("day");
  start = start.startOf("day");
  //filter google data response
  if (data && type === "g") {
    data.map((element) => {
      var arrayVal = [];
      const newElement = {
        data: { rows: [] },
        columnHeader: { dimensions: [] },
      };
      if (element.data.rows)
        arrayVal = element.data.rows.filter(
          (period) =>
            moment(
              period.dimensions[0].replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3"),
              format
            ) <= end &&
            start <=
              moment(
                period.dimensions[0].replace(
                  /(\d{4})(\d{2})(\d{2})/,
                  "$1-$2-$3"
                ),
                format
              )
        );
      newElement.columnHeader.dimensions = Object.values({
        ...element.columnHeader.dimensions,
      });
      newElement.data.rows = Object.values({ ...arrayVal });
      newRawData.push(newElement);
    });
    //filter facebook data response
  } else if (type === "fb") {
    if (data.impressionsDataAge && data.impressionsDataDevice) {
      var arrayValAge = data.impressionsDataAge.filter(
        (filterPeriod) =>
          moment(filterPeriod.date_start, format) <= end &&
          start <= moment(filterPeriod.date_start, format)
      );
      newRawData.impressionsDataAge = Object.values({ ...arrayValAge });

      var arrayValDevice = data.impressionsDataDevice.filter(
        (filterPeriod) =>
          moment(filterPeriod.date_start, format) <= end &&
          start <= moment(filterPeriod.date_start, format)
      );
      newRawData.impressionsDataDevice = Object.values({ ...arrayValDevice });
    } else {
      if (data && data.length > 0) {
        var arrayVal = data.filter(
          (filterPeriod) =>
            moment(filterPeriod.date_start, format) <= end &&
            start <= moment(filterPeriod.date_start, format)
        );
        newRawData = Object.values({ ...arrayVal });
      }
    }
  }
  return newRawData;
}

export {
  googleAnalyticsFormat,
  googleAnalyticsViewFormat,
  appartamentTypeFormat,
  appartamentPlanFormat,
  facebookAdsFormat,
  googleAdsFormat,
  filterDates,
};
