import React from 'react';
import TuningSnackbar from 'components/common/TuningSnackbar';
import { useStore } from 'store/storeUtils.js';
import { observer } from 'mobx-react';

const TuningSnackbarFeedback = observer(() => {
  const store = useStore();

  return (
    <TuningSnackbar
      open={store.snackbarOpen}
      onClose={() => store.setSnackbar(false)}
    >
      <div className='snackbar-body'>{store.snackbarMessage}</div>
    </TuningSnackbar>
  );
});

export default TuningSnackbarFeedback;
