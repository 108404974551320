import React from "react";
import { DateRange } from "react-date-range";
import * as rdrLocales from "react-date-range/dist/locale";
import moment from "utils/MyMoment";
import PropTypes from "prop-types";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import i18n, { Trans } from "@tecma/i18n";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import { DatesFormatter, DatesFormatterRange } from "utils/datesFormatter";
import "styles/dateRange.scss";
import { useStore } from "store/storeUtils";
const TuningDateRange = (props) => {
  const { start, end, min, max, updateEnd, updateStart } = props;

  const [calendarVisible, setCalendarVisible] = React.useState(false);
  const [weekSelected, setWeekSelected] = React.useState(false);
  const [monthSelected, setMonthSelected] = React.useState(false);
  const [numberOfClick, setNumberOfClick] = React.useState(0);
  const store = useStore();
  const theme = useTheme();
  const matchesPhone = useMediaQuery(theme.breakpoints.down("xs"), {
    noSsr: true,
  });

  const handleSelect = (ranges) => {
    setNumberOfClick(numberOfClick + 1);

    let startDate = ranges.selection.startDate;
    let endDate = ranges.selection.endDate;

    let lastSunday = new Date();
    lastSunday.setHours(0, 0, 0, 0);
    let diff =
      lastSunday.getDate() -
      lastSunday.getDay() +
      (lastSunday.getDay() === 0 ? -7 : 0) -
      7;
    lastSunday.setDate(diff);
    let endOfLastWeek = new Date();
    diff = diff + 6;
    endOfLastWeek.setDate(diff);
    endOfLastWeek.setHours(0, 0, 0, 0);

    let date = new Date();
    let firstDayOfLastMonth = new Date(
      date.getFullYear(),
      date.getMonth() - 1,
      1
    );
    let lastDayOfLastMonth = new Date(date.getFullYear(), date.getMonth(), 0);

    if (
      lastSunday.getTime() === startDate.getTime() &&
      endOfLastWeek.getTime() === endDate.getTime()
    ) {
      setWeekSelected(true);
    } else if (
      firstDayOfLastMonth.getTime() === startDate.getTime() &&
      lastDayOfLastMonth.getTime() === endDate.getTime()
    ) {
      setMonthSelected(true);
    } else {
      setWeekSelected(false);
      setMonthSelected(false);
    }
    updateStart(moment(startDate));
    updateEnd(moment(endDate));

    if (endDate.getTime() !== startDate.getTime() || numberOfClick === 1) {
      setCalendarVisible(!calendarVisible);
      setNumberOfClick(0);
    }
  };

  const selectionRange = {
    startDate: start,
    endDate: end,
    key: "selection",
  };

  const selectLastWeek2 = () => {
    let ranges = {
      startDate: null,
      endDate: null,
      key: "selection",
    };
    if (weekSelected === false) {
      let lastSunday = new Date();
      let diff =
        lastSunday.getDate() -
        lastSunday.getDay() +
        (lastSunday.getDay() === 0 ? -7 : 0) -
        7;
      lastSunday.setDate(diff);
      lastSunday.setHours(0, 0, 0, 0);
      let endOfLastWeek = new Date();
      diff = diff + 6;
      endOfLastWeek.setDate(diff);
      endOfLastWeek.setHours(0, 0, 0, 0);
      ranges.startDate = lastSunday;
      ranges.endDate = endOfLastWeek;
    } else {
      let endOfCurrent = new Date();
      endOfCurrent.setHours(0, 0, 0, 0);
      let startOfCurrent = new Date(endOfCurrent.getTime() - 604800000);
      startOfCurrent.setHours(0, 0, 0, 0);
      ranges.startDate = startOfCurrent;
      ranges.endDate = endOfCurrent;
    }
    handleSelect({ selection: ranges });
    setWeekSelected(!weekSelected);
    setMonthSelected(false);
    setCalendarVisible(false);
  };

  const selectLastMonth = () => {
    let ranges = {
      startDate: null,
      endDate: null,
      key: "selection",
    };

    if (monthSelected === false) {
      let today = new Date();
      let lastThirtyDays = moment(today).subtract(30, "days").toDate();
      ranges.startDate = lastThirtyDays;
      ranges.endDate = today;
    } else {
      let endOfCurrent = new Date();
      endOfCurrent.setHours(0, 0, 0, 0);
      let startOfCurrent = new Date(endOfCurrent.getTime() - 604800000);
      startOfCurrent.setHours(0, 0, 0, 0);
      ranges.startDate = startOfCurrent;
      ranges.endDate = endOfCurrent;
    }

    handleSelect({ selection: ranges });
    setWeekSelected(false);
    setMonthSelected(!monthSelected);
    setCalendarVisible(false);
  };

  const dayToFocus = new Date();
  dayToFocus.setMonth(dayToFocus.getMonth() - 1);

  return (
    <div className="position-of-tuning-date-range">
      <div>
        <Button
          className={
            monthSelected ? "date-range-button-selected" : "date-range-button"
          }
          disableRipple={true}
          onClick={selectLastMonth}
        >
          <Trans i18nKey={"label.mese"} />
        </Button>
        <Button
          className={
            weekSelected ? "date-range-button-selected" : "date-range-button"
          }
          disableRipple={true}
          onClick={selectLastWeek2}
        >
          <Trans i18nKey={"label.settimana"} />
        </Button>
      </div>
      {!matchesPhone && (
        <Button
          className={
            calendarVisible
              ? "open-calendar-button-focus"
              : "open-calendar-button"
          }
          disableRipple={true}
          onClick={() => {
            setCalendarVisible(!calendarVisible);
          }}
        >
          {moment(end).diff(moment(start)) <= 0
            ? DatesFormatter(moment(start).toDate())
            : DatesFormatterRange(moment(start).toDate(), moment(end).toDate())}
          <CalendarTodayOutlinedIcon
            style={{ fontSize: "14px", color: "#989898", paddingLeft: "15px" }}
          />
        </Button>
      )}
      {calendarVisible && (
        <DateRange
          key={i18n.language}
          ranges={[selectionRange]}
          locale={
            rdrLocales[i18n.language] || rdrLocales[store.loggedUser.language]
          }
          onChange={handleSelect}
          shownDate={dayToFocus}
          minDate={min}
          maxDate={max}
          months={2}
          direction={"horizontal"}
          showDateDisplay={false}
          showMonthAndYearPickers={false}
          className="date-range-calendar"
          classNames={{
            prevButton: "customPrevButtonClassName",
            nextButton: "customNextButtonClassName",
          }}
        />
      )}
    </div>
  );
};

TuningDateRange.defaultProps = {};
/** prop types */
TuningDateRange.propTypes = {
  /** start: selection start date */
  start: PropTypes.instanceOf(Date),
  /** end: selection end date */
  end: PropTypes.instanceOf(Date),
  /** min: selection minimum date */
  min: PropTypes.instanceOf(Date),
  /** max: selection maximum date */
  max: PropTypes.instanceOf(Date),
  /** updateStart: function to update the start of selection */
  updateStart: PropTypes.func,
  /** updateEnd: function to update the end of selection */
  updateEnd: PropTypes.func,
};

export default TuningDateRange;
