import React from 'react';
import PropTypes from 'prop-types';
import { BarChart, XAxis, YAxis, Bar, ResponsiveContainer } from 'recharts';
import ChartConstants from 'constants/ChartConstants';
import 'styles/charts.scss';
import format from 'utils/FormatThousand';
import Capitalize from 'utils/Capitalize';
import { useTranslation } from '@tecma/i18n';

import { useStore } from 'store/storeUtils';
import { toJS } from 'mobx';
import {SOURCE_DIVIDER, DIRECT_LIST} from "constants/GenericConstants";

/**
 * HorizontalBarReChart
 * based on recharts
 * @component
 * @see {@link http://recharts.org/en-US/api/BarChart  BarChart API}
 */

const HorizontalBarReChart = (props) => {
  const {
    data,
    availableColors,
    backgroundFill,
    height,
    width,
    radius,
  } = props;
 
const { t, i18n } = useTranslation();
  const store = useStore();

  let ourData = toJS(data);

  let total = 0;
  let percentuals = [];
  ourData.forEach((dataElement) => {
    dataElement[0].data = +dataElement[0].data;
    total = total + dataElement[0].data;
  });

  const xAxisTotal = total;

  ourData.forEach((dataElement) => {
    let dataPercent = Math.round((dataElement[0].data * 1000) / total) / 10;
    let label = dataElement[0].label;
    percentuals.push({ label, dataPercent });
  });

  // funzione deterministica per ottenere un colore in base ad una label in ingresso.
  const stringToColor = (str) => {
    let stringUniqueHash = [...str].reduce((acc, char) => {
      return char.charCodeAt(0) + ((acc << 5) - acc);
    }, 0);
    return `#${stringUniqueHash % 360}`;
  }

  // calcolo dei messaggi di default dei grafici delle sorgenti
  const defaultSourceMessage = (lbl) => {
    if(!lbl) return "";
    // restituisce vecchio formato se non è presente il divisore tra channelgrouping e soruce
    if(!lbl.includes(SOURCE_DIVIDER)) return `@${lbl}`;
    const [channel, source] = lbl.split(SOURCE_DIVIDER);
    const values = {channel, source};
    return t([`label.generic${channel}`, `${source} (${channel})`], values)
  }

  return (
    <>
      {data.map((el, index) => {
        const color = availableColors[index] ? availableColors[index] : stringToColor(el[0].label)
        let percentual = percentuals.find((perc) => perc.label === el[0].label);
        return (
          <div key={el[0].label}>
            <div className={ourData.length<8 ?'horizontal-bar-label':'horizontal-bar-label-small'}>
              <div className='horizontal-bar-label-name'>
                {(el[0].label && DIRECT_LIST.includes(el[0].label.toLowerCase())) ? (
                  <a
                    href={`https://${store.assetsUrl}`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {store.assetsUrl}
                  </a>
                ) : (
                  Capitalize(
                    t( [`label.${el[0].label}`,defaultSourceMessage(el[0].label)] )
                  )
                )}
              </div>

              <div className='horizontal-bar-stats'>
                <span>{'(' + format(el[0].data, i18n.language) + ')'}</span>
                <span className='horizontal-bar-percentual-stat'>
                  {percentual.dataPercent + '%'}
                </span>
              </div>
            </div>
            <div className='flex width-100'>
              <ResponsiveContainer width='100%' height={height}>
                <BarChart
                  layout='vertical'
                  width={width}
                  height={height}
                  data={el}
                >
                  <XAxis
                    style={{
                      fontSize: 11,
                      fontWeigth: '300',
                      fontFamily: 'Sagoe',
                    }}
                    domain={[0, xAxisTotal]}
                    hide={true}
                    type='number'
                  />
                  <YAxis
                    style={{
                      fontSize: 11,
                      fontWeigth: '300',
                      fontFamily: 'Sagoe',
                    }}
                    dataKey='label'
                    hide={true}
                    type='category'
                  />
                  <Bar
                    dataKey={'data'}
                    radius={radius}
                    fill={color}
                    background={{ fill: backgroundFill, radius: radius }}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        );
      })}
    </>
  );
};

HorizontalBarReChart.propTypes = {
  /** availableStatuses: list of all statuses to display */
  availableStatuses: PropTypes.arrayOf(PropTypes.string),
  /** availableColors: colors of each bar, depending on statuses */
  availableColors: PropTypes.arrayOf(PropTypes.string),
  /** data */
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
  /** backgroundFill: the background color of the bars */
  backgroundFill: PropTypes.string,
  /** height: of each single bar */
  height: PropTypes.number,
  /** width: of all the chart */
  width: PropTypes.number,
  /** radius: a 4 elements array defining the border radius of bars */
  radius: PropTypes.arrayOf(PropTypes.number),
};

HorizontalBarReChart.defaultProps = {
  availableColors: ChartConstants.defaultColors,
  backgroundFill: '#eeefef',
  data: [],
  height: 22,
  width: 500,
  radius: [0, 0, 0, 0],
};

export default HorizontalBarReChart;
