import React from "react";
import { Route, Redirect } from "react-router-dom";
import PageTemplate from "pages/PageTemplate";
import PropTypes from "prop-types";
import TuningSnackbarFeedback from "components/common/TuningSnackbarFeedback";
import TuningSnackbarError from "components/common/TuningSnackbarError";
import { useStore } from "store/storeUtils";
import { observer } from "mobx-react";
import Client from "client/Client";
import { useTranslation } from "@tecma/i18n";
import { useMutation } from "@apollo/client";
import convertLanguage from "utils/convertLanguage";

const PrivateRoute = observer((props) => {
  const [updateUser] = useMutation(Client.UPDATE_USER);
  const { t, i18n } = useTranslation();

  const store = useStore();

  const setLanguage = (lang, locale) => {
    const oldLanguage = store.loggedUser && store.loggedUser.language;
    const oldLocale = store.loggedUser && store.loggedUser.locale;
    const updateData = { ...store.loggedUser };
    updateData.language = lang;
    updateData.locale = locale;
    delete updateData.id;
    delete updateData.createdOn;
    delete updateData.__typename;
    delete updateData.client;
    updateUser(Client.UPDATE_USER_DEFAULT_OPTIONS(store.loggedUser.id, updateData))
      .then(() => {
        store.setSnackbar(true, t("snackbar.successOperation"));
        store.setSystemLanguage(lang);
        store.setLoggedUserLang(lang, locale);
        i18n.changeLanguage(convertLanguage(lang, locale));
      })
      .catch((e) => {
        if (oldLanguage) {
          store.setLoggedUserLang(oldLanguage, oldLocale);
          store.setSystemLanguage(oldLanguage);
          i18n.changeLanguage(convertLanguage(oldLanguage, oldLocale));
        }
        store.setSnackbarError(true, t("snackbar.failedOperation"));
        console.error(e);
      });
  };

  const { exact, path, ToRender, title, goBack, navbar } = props;

  return (
    <>
      <TuningSnackbarFeedback />
      <TuningSnackbarError />
      <Route
        exact={exact}
        path={path}
        navbar={navbar}
        render={(props) => {
          return store.loggedUser ? (
            <PageTemplate
              BodyComponent={ToRender}
              title={title}
              goBack={goBack}
              setLanguage={setLanguage}
              {...props}
            ></PageTemplate>
          ) : !store.baseUrl ? (
            <></>
          ) : (
            <Redirect exact to="/login" />
          );
        }}
      />
    </>
  );
});

PrivateRoute.propTypes = {
  id: PropTypes.string,
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
  ToRender: PropTypes.elementType.isRequired,
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  goBack: PropTypes.bool,
};

PrivateRoute.defaultProps = {
  exact: false,
  goBack: false,
};

export default PrivateRoute;
