import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  NavLink,
} from "react-router-dom";
import PrivateRoute from "routes/PrivateRoute";
import RouteConsts from "./routes/Routes.jsx";
import { useStore } from "store/storeUtils.js";
import { useLazyQuery, useQuery } from "@apollo/client";
import Client from "client/Client";
import DetectUrl from "utils/DetectUrl";
import TuningLoader from "components/common/TuningLoader";
import ServiceWorkerWrapper from "components/common/ServiceWorkerWrapper";
import SSOComponent from "client/SSOComponent";
import { initI18n } from "@tecma/i18n";
import translation from "./i18n/translations/en/translation.json";
import { useTranslation, loadResources } from "@tecma/i18n";
import LogoutListener from "components/specific/LogoutListener";
import JwtChecker from "utils/JwtChecker";
import convertLanguage from "utils/convertLanguage.jsx";
import inizializeAnalytics from "utils/analytics.js";

export const i18nInit = initI18n(translation, {
  bucketBase: `${process.env.REACT_APP_BUCKET_BASEURL}`,
  productName: "Tuning",
  apiBase: process.env.REACT_APP_API_URI || "",
  expirationTime: 1000 * 60, // 1 minuto,
  cache: "no-cache",
});

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_CODE,
};

inizializeAnalytics(tagManagerArgs);


const App = () => {
  const store = useStore();
  const [loading, setLoading] = React.useState(true);
  const [i18nLoading, seti18nLoading] = React.useState(true);
  const { i18n } = useTranslation();
  //obtain browser language

  const prjInfo = useQuery(
    Client.GET_PROJECT_INFO,
    Client.GET_PROJECT_INFO_DEFAULT_OPTIONS(DetectUrl())
  );

  React.useEffect(() => {
    if (
      prjInfo &&
      !prjInfo.loading &&
      prjInfo.called &&
      prjInfo.data &&
      prjInfo.data.getProjectInfoByHost &&
      loading
    ) {
      const project = { ...prjInfo.data.getProjectInfoByHost };
      store.setAssetsByObject(project);
      store.setBaseUrl(prjInfo.data.getProjectInfoByHost.baseurl);
      document.title = store.pageTitles.tuning
        ? store.pageTitles.tuning
        : `${store.loginProjectName} | Tuning`;
       setLoading(false);
      loadResources({
        id: project.id,
        displayName: project.displayName,
        languages: store.languages,
      }).then(() => {
        seti18nLoading(false);
      });
    }
  }, [prjInfo]);

  React.useEffect(() => {
    if (store.loggedUser)
      i18n.changeLanguage(
        convertLanguage(store.loggedUser.language, store.loggedUser.locale)
      );
  }, [store.loggedUser]);

  if (loading || i18nLoading) {
    return (
      <div className="white-loader">
        <TuningLoader
          type="RevolvingDot"
          color="#00BFFF"
          height={100}
          width={100}
        />
      </div>
    );
  }

  return (
    <>
      <ServiceWorkerWrapper />
      <TuningLoader
        customClass="fixedPosition"
        loading={false}
        hasBackdrop={false}
        z
      />
      <Router>
        <JwtChecker />
        <Switch>
          <Route path="/:path?" component={() => <LayoutRoot />} />
        </Switch>
      </Router>
    </>
  );
};

export const LayoutRoot = () => {
  const store = useStore();
  const { i18n } = useTranslation();

  const prjInfo = useQuery(
    Client.GET_PROJECT_INFO,
    Client.GET_PROJECT_INFO_DEFAULT_OPTIONS(DetectUrl())
  );
  if (
    prjInfo &&
    !prjInfo.loading &&
    prjInfo.called &&
    prjInfo.data &&
    prjInfo.data.getProjectInfoByHost
  ) {
    store.setAssetsByObject({ ...prjInfo.data.getProjectInfoByHost });
    // set page title according to DB info (tuning key)
    document.title = store.pageTitles.tuning;
  }

  const [loadUserInfo, userInfo] = useLazyQuery(Client.GET_USER_INFO);

  const userLanguage = userInfo?.data?.getUserByJWT?.language;
  const userLocale = userInfo?.data?.getUserByJWT?.locale;

  React.useEffect(() => {
    if (userInfo && !userInfo.loading && userInfo.called && userInfo.data) {
      store.setLoggedUser(userInfo.data.getUserByJWT);
      store.setCheckingJwt(false);
      if (
        i18n.language.split("-")[0] !==
          (store?.loggedUser?.language ||
            userInfo?.data?.getUserByJWT?.language) &&
        userLanguage
      ) {
        i18n.changeLanguage(convertLanguage(userLanguage, userLocale));
      }
    }
  }, [userLanguage]);

  React.useEffect(() => {
    store.setCheckingJwt(true);
    if (store.projectId && !userInfo.called) {
      loadUserInfo(Client.GET_USER_INFO_DEFAULT_OPTIONS(store.projectId));
    }
  }, [store.projectId]);

  RouteConsts.map((el) => {
    el.navbar = store.displaySection.includes(el.id);
  });
  return (
    <>
      {store.baseUrl ? (
        <Router basename={store.baseUrl}>
          <NavLink
            activeClassName="active"
            className="logo"
            to="/web-analytics"
          />
          <Switch>
            <Route exact path="/login" component={SSOComponent} />
            {/* TUTTE LE ROUTE, ORDINATE PER ORDER */}
            {RouteConsts.sort((a, b) => a.order - b.order).map((el) =>
              prjInfo &&
              !prjInfo.loading &&
              prjInfo.called &&
              prjInfo.data &&
              prjInfo.data.getProjectInfoByHost ? (
                <PrivateRoute
                  exact={el.exact}
                  key={el.order}
                  path={el.to}
                  ToRender={el.component}
                  title={el.title}
                  goBack={el.goBack}
                  navbar={store.displaySection.includes(el.id)}
                />
              ) : (
                ""
              )
            )}
          </Switch>
          <LogoutListener></LogoutListener>
        </Router>
      ) : null}
    </>
  );
};

export default App;
