import React from "react";
import PropTypes from "prop-types";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Grid from "@material-ui/core/Grid";
import Capitalize from "utils/Capitalize";
import "styles/charts.scss";
import { useTranslation } from "@tecma/i18n";
import TuningSvgLoader from "components/common/TuningSvgLoader";
import Info from "images/assets/info.svg";
import IconTooltip from "@material-ui/core/Tooltip";

import colors from "styles/colors.scss";

import { useMediaQuery, useTheme } from "@material-ui/core";

/**
 * HorizontalLineChart
 * based on recharts
 *
 * @component
 * @see {@link http://recharts.org/en-US/api/LineChart LineChart API}
 */

const HorizontalLineChart = (props) => {
  const { data, width, height, labels } = props;
  const { t } = useTranslation();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });

  let myHeight = height;

  if (matches) {
    myHeight = 350;
  }

  const customColors = [
    colors.ARANCIO,
    colors.VIOLA,
    colors.AZZURRO,
    colors.DIRECT,
    colors.SOCIAL,
    colors.OTHER,
    colors.ORGANIC,
  ];
  const legendColors = (label) => {
    let legendColors = {};
    labels.map((label, i) => {
      label === "website"
        ? (legendColors.website = colors.VERDE_CHIARO)
        : label === "total"
        ? (legendColors.total = colors.VERDE)
        : label === "landing"
        ? (legendColors.landing = colors.GIALLO)
        : (legendColors[label] = customColors[i]);
    });
    return legendColors[label];
  };

  const [selectedLine, setSelectedLine] = React.useState(null);

  const [overLine, setOverLine] = React.useState("");
  const [coordinate, setCoordinate] = React.useState();

  const customOver = (e) => {
    setOverLine(e.dataKey);
    let cx = e.cx + 5;
    let cy = e.cy - 20;
    setCoordinate({ x: cx, y: cy });
  };

  let yaxisLimit = 0;
  data.forEach((dataElement) => {
    yaxisLimit = Math.max(yaxisLimit, dataElement.total);
  });

  const CustomTooltip = (dataTooltip) => {
    if (
      dataTooltip.active &&
      dataTooltip.payload &&
      dataTooltip.payload[0] !== null
    ) {
      if (dataTooltip.payload[0].payload[overLine]) {
        return (
          <div className="recharts-tooltip-wrapper">
            <div className="recharts-default-tooltip custom-tooltip-bottom line-chart">
              <p className="recharts-tooltip-label">{`${Capitalize(
                t([`label.${overLine}`, overLine])
              )} : ${dataTooltip.payload[0].payload[overLine]}`}</p>
            </div>
          </div>
        );
      }
      return null;
    }
    return null;
  };

  return (
    <>
      <ResponsiveContainer width="100%" height={myHeight}>
        <LineChart data={data} width={width} height={myHeight}>
          <CartesianGrid
            strokeDasharray="4"
            stroke="#E8E9EB"
            strokeWidth="1px"
          />
          <XAxis
            axisLine={false}
            style={{
              fontSize: 11,
              fontWeigth: "400",
              fontFamily: "Sagoe",
            }}
            height={25}
            dataKey="name"
            tickLine={false}
            tickMargin={15}
            allowDataOverflow={true}
          />
          <YAxis
            axisLine={false}
            style={{
              fontSize: 11,
              fontWeigth: "400",
              fontFamily: "Sagoe",
            }}
            width={40}
            tickMargin={10}
            tickLine={false}
          />
          <Tooltip
            content={<CustomTooltip />}
            position={coordinate}
            allowEscapeViewBox={{ x: false, y: false }}
          />
          <Legend
            verticalAlign="bottom"
            content={() => {
              return (
                <>
                  <Grid container className="legend-items">
                    {labels.map((pay) => (
                      <Grid key={pay} item>
                        <div
                          className="legend-line"
                          onClick={() =>
                            selectedLine && selectedLine === pay
                              ? setSelectedLine(null)
                              : setSelectedLine(pay)
                          }
                        >
                          <div
                            className="donut-legend-icon"
                            style={{
                              backgroundColor: legendColors(pay),
                              margin: "0px 3px 7px",
                            }}
                          ></div>
                          <span className="small-font-14 padding-right-5">
                            {Capitalize(t([`label.${pay}`, pay]))}
                          </span>
                          {pay === "website" ? (
                            <IconTooltip
                              title={t("website.tooltip.title")}
                              enterTouchDelay={100}
                              classes={{ popper: "tooltip-legend" }}
                              PopperProps={{
                                placement: "bottom",
                                modifiers: {
                                  offset: { offset: "0, -12px" },
                                },
                              }}
                            >
                              <span>
                                <TuningSvgLoader
                                  data={Info}
                                  alt="website info"
                                />
                              </span>
                            </IconTooltip>
                          ) : (
                            ""
                          )}
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </>
              );
            }}
          />

          {labels.map((stat, i) => (
            <Line
              key={i}
              type="monotone"
              dataKey={stat}
              stroke={legendColors(stat)}
              dot={false}
              className={
                selectedLine && selectedLine !== stat ? "line-visibility" : ""
              }
              activeDot={{ onMouseOver: customOver }}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

HorizontalLineChart.propTypes = {
  /**
   * availableStatuses: all statuses to be displayed on chart and legend
   */
  avaliableLabelLines: PropTypes.arrayOf(PropTypes.string),
  /**
   * availableColors: colors to use for each status
   */
  availableColors: PropTypes.arrayOf(PropTypes.string),
  /**
   * data
   */
  data: PropTypes.arrayOf(PropTypes.object),
  /**
   * width: of the chart
   */
  width: PropTypes.number,
  /**
   * height: of all the elements
   */
  height: PropTypes.number,
  /**
   * innerRadius: 0 is a pie chart. The higher it is, the bigger the "hole" at the center of the chart will be
   */
  radius: PropTypes.arrayOf(PropTypes.number),
  /**
   * labels
   */
  labels: PropTypes.arrayOf(PropTypes.string),
};

HorizontalLineChart.defaultProps = {
  data: [],
  width: 550,
  height: 290,
  radius: [10, 10, 0, 0],
};

export default HorizontalLineChart;
