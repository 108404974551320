import { createContext, useContext } from 'react';
import Store from 'store/store.js';

/**
 * A context for the mobx store
 * @see {@link https://reactjs.org/docs/context.html|React context}
 * @see {@link https://mobx.js.org/|mobx}
 */
export const StoreContext = createContext(Store);

/**
 * A provider to inject the mobx store in the application
 * @see {@link https://reactjs.org/docs/context.html|React context}
 * @see {@link https://mobx.js.org/|mobx}
 */
export const StoreProvider = StoreContext.Provider;

/**
 * A hook allowing the use of the mobx store in all the application
 * @see StoreProvider
 * @see {@link https://reactjs.org/docs/hooks-intro.html|React hooks}
 * @see {@link https://mobx.js.org/|mobx}
 */
export const useStore = () => useContext(StoreContext);
