import React from 'react';
import { useStore } from 'store/storeUtils.js';
import { observer } from 'mobx-react';
import { Menu, Button } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import MailTo from 'constants/MailTo.jsx';
import 'styles/avatar.scss';
import {    Trans } from '@tecma/i18n';
 
import TuningSvgLoader from 'components/common/TuningSvgLoader';
import UserLogo from 'images/assets/user_white.svg';

/**
 * Default avatar
 * based on material-ui Avatar
 * @component
 * @see {@link https://material-ui.com/api/avatar/ Avatar API}
 */
const TuningAvatar = observer(() => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const store = useStore();

  const openMenu = (e) => {
    if (!anchorEl) {
      setAnchorEl(e.target);
    }
    store.setAvatarMenu(true);
  };

  const close = () => {
    store.setAvatarMenu(false);
  };

  const logOut = () => {
    store.forceLogout(true); // prima passa per il componente "LogoutListener" per la pulizia dello store e poi avverrà il reindirizzamento
  };

  return (
    <>
      <div className='avatar-wrapper'>
        <Button
          aria-controls='simple-menu'
          className='user-button'
          onClick={openMenu}
          disableRipple={true}
        >
          <TuningSvgLoader data={UserLogo} alt='User' className='user-logo' />
          <div className="logged-user-name">{store.loggedUserFullname.toLowerCase()}</div>
        </Button>
        {store.avatarMenu && (
          <Menu
            id='menu2'
            anchorEl={anchorEl}
            keepMounted
            open={store.avatarMenu}
            onClose={close}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            classes={{
              paper: 'no-border user-option-selection',
            }}
          >
            <MenuItem
              classes={{
                root: 'inline-block',
              }}
              component='a'
              href={MailTo}
            >
              <Trans
                i18nKey={'tuning.feedback'}
               
              />
            </MenuItem>
            <Divider variant='middle' />
            <MenuItem
              classes={{
                root: 'inline-block',
              }}
              onClick={logOut}
            >
              <Trans i18nKey={'tuning.logout'}   />
            </MenuItem>
          </Menu>
        )}
      </div>
    </>
  );
});

export default TuningAvatar;
