import React from 'react';
import { PropTypes } from 'prop-types';
import 'styles/charts.scss';
import PadNumber from 'utils/PadNumber';
import format from 'utils/FormatThousand';
import { Trans, useTranslation } from '@tecma/i18n';
/**
 * ContentWrapper
 * @component
 */
const ContentWrapper = (props) => {
  const { text, dataNumber, type } = props;
  const { i18n } = useTranslation();

  const number = (data, defType) => {
    var number = null;
    switch (defType) {
      case 'number':
        number = format(Math.floor(data * 100) / 100, i18n.language);
        break;
      case 'time':
        let num = Math.floor(data / 60);
        let hours = Math.floor(num / 60);
        let minutes = num % 60;
        let seconds = Math.floor(data - num * 60);
        number =
          PadNumber(hours, 2) +
          ':' +
          PadNumber(minutes, 2) +
          ':' +
          PadNumber(seconds, 2);
        break;
      case 'percent':
        number = Math.round(data * 100) / 100 + '%';
        break;
      default:
        number = 'NAN';
    }
    return number;
  };

  return (
    <div className='table-cell'>
      <div className='table-write'>
        <div className='small-font-14'>{text}</div>
        <div className='number-content'>{number(dataNumber, type)}</div>
      </div>
    </div>
  );
};

ContentWrapper.propTypes = {
  /** fatturato: the main value to display */
  type: PropTypes.string,
  text: PropTypes.string,
  dataNumber: PropTypes.number,
};

ContentWrapper.defaultProps = {
  type: null,
  text: <Trans i18nKey={'tuning.empty' }/>,
  dataNumber: 0,
};

export default ContentWrapper;
