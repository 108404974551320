import { observable, action, decorate, computed } from "mobx";
import { persist } from "mobx-persist";
import Initials from "utils/Initials";
import ValidateEmail from "utils/ValidateEmail";
import ValidatePhone from "utils/ValidatePhone";
import Capitalize from "utils/Capitalize";
import { filterDates } from "utils/formatResponse";
import moment from "moment";
import { isEqual, omit } from "lodash";
import convertLanguage from "utils/convertLanguage";

/** Class representing global state of the app.
 * Store is decorated with mobx.
 * It uses the following mobx and mobx-persist decorators:
 * - observable: variable is set as observable (observers will be notified if the variable is updated)
 * - persist: variable will be persisted in a storage
 * - action(.bound): the function is enabled to update the store. Its "this" is bound to the store.
 * - computed: after an observable update, this function automatically updates a complex variable
 * @see {@link https://mobx.js.org/|mobx}
 * @see {@link https://www.npmjs.com/package/mobx-persist|mobx-persist}
 */
class Store {
  /**
   * initializes store.
   * @constructor
   */
  constructor() {
    this.feKey = "";
    this.feVendorKey = "";
    this.breadCrumbs = null;
    this.loginProjectName = "";
    this.displaySection = ["web"];
    this.assetsUrl = null;
    this.projectName = null;
    this.typologies = [];
    this.currentNavTab = 0;
    this.apartmentId = 0;
    this.clientId = 0;
    this.vendorEmail = "";
    this.vendorPassword = "";
    this.route = [];
    this.errorModal = false;
    this.logo = [];
    this.assetLogin = [];
    this.externalPdf = null;
    this.menuMobile = false;
    this.avatarMenu = false;
    this.appartmentModal = false;
    this.addingAppartments = false;
    this.removeAppartmentModal = false;
    this.modifyingAppartmentOriginalState = null;
    this.removeAppartment = null;
    this.modifyingAppartment = {
      id: "",
      name: "",
      status: "",
    };
    this.snackbarOpen = false;
    this.snackbarMessage = undefined;
    this.snackbarError = false;
    this.clientToAdd = null;
    this.selectedClient = null;
    this.clientToSave = null;
    this.appartmentToSave = null;
    this.selectedAppartment = null;
    this.loggedUser = null;
    this.loggedToken = null;
    this.realLocation = undefined;
    this.longLoading = false;
    this.globalEditing = false;
    this.pdfToView = undefined;
    this.period_landing = { start: null, end: null, type: null };
    this.period_home = { start: null, end: null, type: null };
    this.period_fbAds = { start: null, end: null, type: null };
    this.period_googleAds = { start: null, end: null, type: null };
    this.def_period = "newPeriod";
    this.def_time_cache_reset = 12;
    this.projectId = null;
    this.pageTitles = {};
    this.vendorLogo = [];

    this.google_analytics = { all: {}, userData: [], dataElement: [] };
    this.google_analytics_landing = { all: {}, userData: [], dataElement: [] };
    this.google_ads = {};
    this.selling_analytics = {};
    this.facebook_adsClick = {};
    this.facebook_adsImpressions = {};
    this.fbClickRaw = {};
    this.fbImpressionRaw = {};
    this.googleRaw = {};
    this.googleRawModify = [];
    this.googleRawLanding = {};
    this.googleRawModifyLanding = [];
    this.fbClickRawModify = [];
    this.fbImpressionRawModify = [];
    this.loadingHome = true;

    this.messages = {
      language: "",
      translations: {},
    };
    this.systemLanguage = null;

    this.openDrawer = undefined;
    this.redirectionToSsoNeeded = false;
    this.checkingJwt = false;
    this.languages = undefined;
    this.baseUrl = undefined;
    this.enabledTools = null;
  }

  /* ACTIONS */
  setBaseUrl(val) {
    this.baseUrl = val;
  }

  /**
   * updates tab.
   * @param {number} newTab the new selected tab (id)
   * @param {string} location
   */
  updateCurrentNavTab(newTab, location) {
    if (newTab !== this.currentNavTab) {
      this.currentNavTab = newTab;
    }
    this.setRealLocation(location);
  }

  /**
   * sets new location
   * @param {string} val
   */
  setRealLocation(val) {
    this.realLocation = val;
  }

  /**
   * sets avatar's menu state (open/closed).
   * @param {boolean} val state of the menu
   */
  setAvatarMenu(val) {
    this.avatarMenu = val;
  }

  /**
   * toggles mobile menu state (open/closed).
   */
  toggleMenuMobile() {
    this.menuMobile = !this.menuMobile;
  }

  /**
   * closes Menu Mobile drawer
   */
  closeMenuMobile() {
    this.menuMobile = false;
  }

  /**
   * sets the selected client
   * @param {Object} userData the selected client object
   */
  setSelectedClient(userData) {
    this.clientToSave = null;
    this.selectedClient = userData;
  }

  /**
   * sets the selected appartment
   * @param {Object} appartmentData the selected appartment object
   */
  setSelectedAppartment(appartmentData) {
    this.appartmentToSave = null;
    this.selectedAppartment = appartmentData;
  }

  /**
   * sets the logged user
   * @param {Object} userData the logged user object
   */
  setLoggedUser(userData) {
    if (
      !this.loggedUser ||
      !isEqual(
        omit(this.loggedUser, ["language", "TwoFA"]),
        omit(userData, ["language", "TwoFA"])
      )
    ) {
      this.loggedUser = userData;
    }
  }

  /**
   * update logged user data
   * @param {Object} updatedData object containing updated data
   */
  updateLoggedUser(updatedData) {
    this.loggedUser = { ...this.loggedUser, ...updatedData };
  }

  setCheckingJwt(val) {
    this.checkingJwt = val;
  }

  /**
   * sets the logged user language
   * @param {Object} userData the logged user object
   */
  setLoggedUserLang(language) {
    if (language) {
      this.loggedUser.language = language;
    }
  }

  /**
   * sets the logged token
   * @param {Object} tokenData the logged token object
   */
  setLoggedToken(tokenData) {
    if (!this.loggedToken) {
      this.loggedToken = tokenData;
    }
  }

  /**
   * sets the loging in vendor password
   * @param {string} val the password
   */
  updateVendorPassword(val) {
    if (this.vendorPassword !== val) {
      this.vendorPassword = val;
    }
  }
  /**
   * sets the loging in vendor email
   * @param {string} val the email
   */
  updateVendorEmail(val) {
    if (this.vendorEmail !== val) {
      this.vendorEmail = val;
    }
  }

  /**
   * sets the current project name
   * @param {string} pName project name
   */
  updateProjectName(pName) {
    if (this.projectName !== pName) {
      this.projectName = pName;
    }
  }

  /**
   * sets the selected client id
   * @param {number} id the selected client id
   */
  setClientId(id) {
    this.clientId = id;
  }

  /**
   * sets the selected appartment id
   * @param {number} id the selected appartment id
   */
  setApartmentId(id) {
    this.apartmentId = id;
  }

  /**
   * resets all persisted data
   */
  logOut() {
    this.feKey = "";
    this.feVendorKey = "";
    this.loginProjectName = "";
    this.projectName = null;
    this.logo = [];
    this.assetLogin = [];
    this.assetsUrl = null;
    this.displaySection = [];
    this.typologies = [];
    this.currentNavTab = 0;
    this.apartmentId = 0;
    this.snackbarOpen = false;
    this.snackbarMessage = undefined;
    this.snackbarError = false;
    this.clientId = 0;
    this.realLocation = undefined;
    this.vendorEmail = "";
    this.vendorPassword = "";
    this.modifyingAppartment = {
      id: "",
      name: "",
      status: "",
    };
    this.menuMobile = false;
    this.avatarMenu = false;
    this.errorModal = false;
    this.appartmentModal = false;
    this.modifyingAppartmentOriginalState = null;
    this.addingAppartments = false;
    this.removeAppartmentModal = false;
    this.removeAppartment = null;
    this.externalPdf = null;
    this.clientToAdd = null;
    this.selectedClient = null;
    this.clientToSave = null;
    this.appartmentToSave = null;
    this.selectedAppartment = null;
    this.loggedUser = null;
    this.loggedToken = null;
    this.longLoading = false;
    this.globalEditing = false;
    this.pdfToView = undefined;
    this.period_landing = { start: null, end: null, type: null };
    this.period_home = { start: null, end: null, type: null };
    this.period_fbAds = { start: null, end: null, type: null };
    this.period_googleAds = { start: null, end: null, type: null };
    this.projectId = null;
    this.pageTitles = {};
    this.vendorLogo = [];
    this.checkingJwt = false;

    this.google_analytics = { all: {}, userData: [], dataElement: [] };
    this.google_analytics_landing = { all: {}, userData: [], dataElement: [] };
    this.google_ads = {};
    this.selling_analytics = {};
    this.facebook_adsClick = {};
    this.facebook_adsImpressions = {};
    this.fbClickRaw = {};
    this.fbImpressionRaw = {};
    this.googleRaw = {};
    this.googleRawModify = {};
    this.googleRawLanding = {};
    this.googleRawModifyLanding = {};
    this.fbClickRawModify = {};
    this.fbImpressionRawModify = {};
    this.languages = undefined;
  }

  /**
   *
   */
  setLongLoading(val) {
    if (this.longLoading !== val) {
      this.longLoading = val;
    }
  }

  /**
   * sets global editing on a form
   * @param {bool} val
   */
  setGlobalEditing(val) {
    this.globalEditing = val;
  }

  setDefineMaxMin() {
    const format = "YYYY-MM-DD";
    const minDate =
      this.googleRaw[0] && this.googleRaw[0].data && this.googleRaw[0].data.rows
        ? moment(
            this.googleRaw[0].data.rows[0].dimensions[0].replace(
              /(\d{4})(\d{2})(\d{2})/,
              "$1-$2-$3"
            ),
            format
          )
        : null;
    //const lastValue = this.googleRaw[0].data.rows ? this.googleRaw[0].data.rows.length-1 : null
    const maxDate = moment();
    //this.googleRaw[0].data.rows ? moment((this.googleRaw[0].data.rows[lastValue].dimensions[0]).replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3'), format) : null
    this.period_home["max"] = maxDate;
    this.period_home["min"] = minDate;
  }
  setDefineMaxMinLanding() {
    const format = "YYYY-MM-DD";
    const minDate =
      this.googleRawLanding[0] &&
      this.googleRawLanding[0].data &&
      this.googleRawLanding[0].data.rows
        ? moment(
            this.googleRawLanding[0].data.rows[0].dimensions[0].replace(
              /(\d{4})(\d{2})(\d{2})/,
              "$1-$2-$3"
            ),
            format
          )
        : null;
    //const lastValue = this.googleRawLanding[0].data.rows ? this.googleRawLanding[0].data.rows.length-1 : null
    const maxDate = moment();
    //this.googleRawLanding[0].data.rows ? moment((this.googleRawLanding[0].data.rows[lastValue].dimensions[0]).replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3'), format) : null
    this.period_landing["max"] = maxDate;
    this.period_landing["min"] = minDate;
  }
  setDefineMaxMinGoogleAds() {
    const format = "YYYY-MM-DD";
    const minDate =
      this.googleRaw[4] && this.googleRaw[4].data && this.googleRaw[4].data.rows
        ? moment(
            this.googleRaw[4].data.rows[0].dimensions[0].replace(
              /(\d{4})(\d{2})(\d{2})/,
              "$1-$2-$3"
            ),
            format
          )
        : null;
    //const lastValue = this.googleRaw[0].data.rows ? this.googleRaw[4].data.rows.length-1: null
    const maxDate = moment();
    //this.googleRaw[0].data.rows ? moment((this.googleRaw[4].data.rows[lastValue].dimensions[0]).replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3'), format): null
    this.period_googleAds["max"] = maxDate;
    this.period_googleAds["min"] = minDate;
  }

  setDefineMaxMinFb() {
    const format = "YYYY-MM-DD";
    //const lastValue = this.fbClickRaw.length -1
    const minDate =
      this.fbClickRaw.length > 0
        ? moment(this.fbClickRaw[0].date_start, format)
        : null;
    const maxDate = moment();
    //this.fbClickRaw.length>0 ? moment(this.fbClickRaw[lastValue].date_start, format) : null
    this.period_fbAds["max"] = maxDate;
    this.period_fbAds["min"] = minDate;
  }
  setHomeEndPeriod(newPeriod, type) {
    if (this.period_home) {
      this.period_home.type = type;
      this.period_home.end = newPeriod;
    }
    this.period_googleAds = { start: null, end: null, type: null };
    this.period_landing = { start: null, end: null, type: null };
  }
  setHomeStartPeriod(newPeriod, type) {
    if (this.period_home) {
      this.period_home.type = type;
      this.period_home.start = newPeriod;
    }
    this.period_googleAds = { start: null, end: null, type: null };
    this.period_landing = { start: null, end: null, type: null };
  }

  setLandingEndPeriod(newPeriod, type) {
    if (this.period_landing) {
      this.period_landing.type = type;
      this.period_landing.end = newPeriod;
    }
    this.period_googleAds = { start: null, end: null, type: null };
    this.period_home = { start: null, end: null, type: null };
  }
  setLandingStartPeriod(newPeriod, type) {
    if (this.period_landing) {
      this.period_landing.type = type;
      this.period_landing.start = newPeriod;
    }
    this.period_googleAds = { start: null, end: null, type: null };
    this.period_home = { start: null, end: null, type: null };
  }

  setGoogleAdsStartPeriod(newPeriod, type) {
    if (this.period_googleAds) {
      this.period_googleAds.type = type;
      this.period_googleAds.start = newPeriod;
    }
    this.period_landing = { start: null, end: null, type: null };
    this.period_home = { start: null, end: null, type: null };
  }
  setGoogleAdsEndPeriod(newPeriod, type) {
    if (this.period_googleAds) {
      this.period_googleAds.type = type;
      this.period_googleAds.end = newPeriod;
    }
    this.period_landing = { start: null, end: null, type: null };
    this.period_home = { start: null, end: null, type: null };
  }

  setFbAdsStartPeriod(newPeriod, type) {
    if (this.period_fbAds) {
      this.period_fbAds.type = type;
      this.period_fbAds.start = newPeriod;
    }
  }
  setFbAdsEndPeriod(newPeriod, type) {
    if (this.period_fbAds) {
      this.period_fbAds.type = type;
      this.period_fbAds.end = newPeriod;
    }
  }

  setGoogleDataRaw(response) {
    this.googleRaw = response[0];
    this.googleRawModify = [response[0], response[0]];
  }

  setGoogleDataRawLanding(response) {
    this.googleRawLanding = response[0];
    this.googleRawModifyLanding = [response[0], response[0]];
  }

  setFacebookImpressionDataRaw(response) {
    this.fbImpressionRaw = response[0];
    this.fbImpressionRawModify = [response[0], response[0]];
  }

  setFacebookClickDataRaw(response) {
    this.fbClickRaw = response[0];
    this.fbClickRawModify = [response[0], response[0]];
  }

  setNewPeriodGoogle() {
    this.googleRawModify.pop();
    this.googleRawModify.push(Object.values({ ...this.googleRawModify[0] }));
  }

  setNewPeriodFbImp() {
    //this.fbImpressionRawModify.pop()
    this.fbImpressionRawModify.push(
      Object.values({ ...this.fbImpressionRawModify[0] })
    );
  }

  setNewPeriodFbClick() {
    //this.fbClickRawModify.pop()
    this.fbClickRawModify.push(Object.values({ ...this.fbClickRawModify[0] }));
  }

  setGoogleAnalytics(googleMap) {
    this.google_analytics.all = googleMap;
    this.google_analytics.userData = [
      googleMap.totalUsr,
      googleMap.sessions,
      googleMap.usrSessions,
    ];
    this.google_analytics.dataElement = [
      googleMap.pageView,
      googleMap.avgSession,
      googleMap.pageviewsPerSession,
      googleMap.avgTimePage,
      googleMap.percentNewSessions,
      googleMap.bounceRate,
    ];
  }

  setGoogleAnalyticsLanding(googleMap) {
    this.google_analytics_landing.all = googleMap;
    this.google_analytics_landing.userData = [
      googleMap.totalUsr,
      googleMap.sessions,
      googleMap.usrSessions,
    ];
    this.google_analytics_landing.dataElement = [
      googleMap.pageView,
      googleMap.avgSession,
      googleMap.pageviewsPerSession,
      googleMap.avgTimePage,
      googleMap.percentNewSessions,
      googleMap.bounceRate,
    ];
  }

  setAppartamentType(verticalGraphData) {
    this.selling_analytics["appartamentGraph"] = verticalGraphData;
  }

  setAppartamentPlan(Tabedata) {
    this.selling_analytics["tableData"] = Tabedata;
  }

  setFacebookAdsClick(facebookMap) {
    this.facebook_adsClick = facebookMap;
  }

  setFacebookAdsImpressions(facebookMap) {
    this.facebook_adsImpressions = facebookMap;
  }

  setGoogleAds(googleMap) {
    this.google_ads = googleMap;
  }
  /**
   * TODO: this method should be removed in a future release, as assets
   * and projectName should come from BE
   * @param {string} assetKey the key to choose fe assets (i.e. images)
   * @param {string} projectName the projectName to use in BE fetches
   * @param {string} displayName the name to display at login
   * @param {string} assetUrl the URL to retrieve images
   */
  setAssets(assetKey, projectName, displayName, assetsUrl) {
    this.feKey = assetKey;
    this.projectName = projectName;
    this.loginProjectName = displayName;
    this.assetsUrl = assetsUrl;
  }

  setRoute(newRoute) {
    this.route = newRoute;
  }
  /**
   * TODO: this method should be removed in a future release, as assets
   * and projectName should come from BE
   * @param {object} obj an object containing all assets
   */
  setAssetsByObject(obj) {
    if (obj) {
      this.feKey = obj.assetKey;
      this.assetLogin = obj.assetLogin;
      this.logo = obj.logo;
      this.externalPdf = obj.pdf;
      this.projectName = obj.projectName;
      this.projectId = obj.id;
      this.loginProjectName = obj.displayName;
      this.assetsUrl = obj.hostKey;
      this.feVendorKey = obj.feVendorKey;
      this.displaySection = obj.enabled_analytics;
      this.vendorLogo = obj.vendorLogo;
      this.pageTitles = obj.pageTitles;
      this.languages = obj.tuningConfig
        ? obj.tuningConfig.languages
        : undefined;
      this.enabledTools = obj.enabledTools;
    }
  }
  /**
   * set appartment typologies
   * @param {array} data an array of typologies
   */
  setAppartmentTypologies(data) {
    this.typologies = data;
  }

  /**
   * open/close error modal
   * @param {boolean} val
   */
  setOpenDrawer(val) {
    this.openDrawer = val;
  }

  /**
   * open/close error modal
   * @param {boolean} val
   */
  setErrorModal(val) {
    this.errorModal = val;
  }

  /**
   * updates client to save
   * @param {object} data updated client
   */
  updateClientToSave(data) {
    this.clientToSave = data;
  }

  /**
   * updates client to add
   * @param {object} data client to add
   */
  updateClientToAdd(data) {
    this.clientToAdd = data;
  }

  /**
   * updates appartment to save
   * @param {object} data updated client
   */
  updateAppartmentToSave(data) {
    this.appartmentToSave = data;
  }

  /**
   * sets breadcrumbs
   * @param {string} bread parent bread crumb
   */
  setBreadCrumbs(bread) {
    this.breadCrumbs = bread;
  }

  /**
   * set appartment modal open/close
   * @param {boolean} val  appartmentmodal
   * @param {boolean} val2 adding appartments
   */
  setAppartmentModal(val, val2) {
    this.appartmentModal = val;
    this.addingAppartments = val2;
  }

  /**
   * set remove appartment modal open/close
   * @param {boolean} val  appartmentmodal
   */
  setRemoveModal(val) {
    this.removeAppartmentModal = val;
  }

  /**
   * sets currently modifying appartment
   * @param {object} app
   */
  setUpdateSelectedAppartment(app) {
    if (!app || app === {}) {
      this.modifyingAppartment = {
        id: "",
        name: "",
        status: "",
      };
    } else {
      this.modifyingAppartment = app;
    }
  }

  /**
   * sets currently modifying appartment original status
   * @param {string} state
   */
  setOrignalStateUpdateSelectedAppartment(state) {
    this.modifyingAppartmentOriginalState = state;
  }

  /**
   * set appartment to remove
   * @param {object} val
   */
  setRemoveAppartment(val) {
    this.removeAppartment = val;
  }

  /**
   *
   * @param {object} v1 clientToSave
   * @param {object} v2 selecteClient
   * @param {func} callback
   */
  updateGloballyClient(v1, v2, callback) {
    this.clientToSave = v1;
    this.selectedClient = v2;
    if (callback) {
      callback();
    }
  }

  /**
   * sets snackbar open/closed
   * @param {boolean} val
   * @param {string} mes message to display
   */
  setSnackbar(val, mes) {
    if (val !== this.snackbarOpen) {
      this.snackbarMessage = mes;
      this.snackbarOpen = val;
    }
  }

  /**
   * sets snackbar error open/closed
   * @param {boolean} err
   * @param {string} mes message to display
   */
  setSnackbarError(err, mes) {
    if (err !== this.snackbarError) {
      this.snackbarMessage = mes;
      this.snackbarError = err;
    }
  }

  /**
   * sets pdf to view in pdf viewer
   * @param {string} val
   */
  setPdfToView(val) {
    this.pdfToView = val;
  }

  /**
   * set the language of the system
   * @param {string} val
   */
  setSystemLanguage(val) {
    this.systemLanguage = val;
  }

  setLoadingHome(val) {
    this.loadingHome = val;
  }

  forceLogout(logout) {
    this.redirectionToSsoNeeded = logout;
  }

  /* COMPUTED */

  /**
   * computes the initials of the currently logged user
   */

  get initials() {
    if (
      this.loggedUser &&
      this.loggedUser.firstName &&
      this.loggedUser.lastName
    ) {
      return Initials(this.loggedUser.firstName, this.loggedUser.lastName);
    } else {
      return "-";
    }
  }

  /**
   * computes the data of the period to display for google analyticspages
   */
  get googleAnalyticsByDate() {
    var newRawData = null;
    if (this.googleRaw) {
      const rawNewDate = this.googleRawModify[1];
      if (this.period_landing.type) {
        if (this.period_landing.type === "all") newRawData = this.googleRaw;
        else if (this.period_landing.type === "newPeriod")
          newRawData = filterDates(
            rawNewDate,
            "g",
            this.period_landing.start,
            this.period_landing.end
          );
      } else if (this.period_home.type) {
        if (this.period_home.type === "all") newRawData = this.googleRaw;
        else if (this.period_home.type === "newPeriod")
          newRawData = filterDates(
            rawNewDate,
            "g",
            this.period_home.start,
            this.period_home.end
          );
      } else if (this.period_googleAds.type) {
        if (this.period_googleAds.type === "all") newRawData = this.googleRaw;
        else if (this.period_googleAds.type === "newPeriod")
          newRawData = filterDates(
            rawNewDate,
            "g",
            this.period_googleAds.start,
            this.period_googleAds.end
          );
      }
    }
    return newRawData;
  }

  get googleAnalyticsByDateLanding() {
    var newRawData = null;
    if (this.googleRawLanding) {
      const rawNewDate = this.googleRawModifyLanding[1];
      if (this.period_landing.type) {
        if (this.period_landing.type === "all") newRawData = this.googleRaw;
        else if (this.period_landing.type === "newPeriod")
          newRawData = filterDates(
            rawNewDate,
            "g",
            this.period_landing.start,
            this.period_landing.end
          );
      } else if (this.period_home.type) {
        if (this.period_home.type === "all") newRawData = this.googleRawLanding;
        else if (this.period_home.type === "newPeriod")
          newRawData = filterDates(
            rawNewDate,
            "g",
            this.period_home.start,
            this.period_home.end
          );
      } else if (this.period_googleAds.type) {
        if (this.period_googleAds.type === "all") newRawData = this.googleRaw;
        else if (this.period_googleAds.type === "newPeriod")
          newRawData = filterDates(
            rawNewDate,
            "g",
            this.period_googleAds.start,
            this.period_googleAds.end
          );
      }
    }
    return newRawData;
  }
  /**
   * computed the data of the facebook Ads page for the selected period
   */
  get facebookImpressionsByDate() {
    var newRawData = null;
    if (this.fbImpressionRaw) {
      const rawNewDate = this.fbImpressionRawModify[1];
      if (this.period_fbAds) {
        if (this.period_fbAds.type === "all") newRawData = this.fbImpressionRaw;
        else if (this.period_fbAds.type === "newPeriod")
          newRawData = filterDates(
            rawNewDate,
            "fb",
            this.period_fbAds.start,
            this.period_fbAds.end
          );
      }
    }
    return newRawData;
  }
  get facebookClicksByDate() {
    var newRawData = null;
    if (this.fbClickRaw) {
      const rawNewDate = this.fbClickRawModify[1];
      if (this.period_fbAds) {
        if (this.period_fbAds.type === "all") newRawData = this.fbClickRaw;
        else if (this.period_fbAds.type === "newPeriod")
          newRawData = filterDates(
            rawNewDate,
            "fb",
            this.period_fbAds.start,
            this.period_fbAds.end
          );
      }
    }
    return newRawData;
  }

  /**
   * computes the logged user full name
   */
  get loggedUserFullname() {
    if (
      this.loggedUser &&
      this.loggedUser.firstName &&
      this.loggedUser.lastName
    ) {
      return `${this.loggedUser.firstName} ${this.loggedUser.lastName}`;
    } else if (this.loggedUser && this.loggedUser.id) {
      return this.loggedUser.id;
    } else {
      return "-";
    }
  }

  /**
   * computes an array of names
   */
  get availableSizes() {
    return this.typologies.map((e) => Capitalize(e.name));
  }

  /**
   * computes the selected client initials
   */
  get clientInitials() {
    if (
      this.selectedClient &&
      this.selectedClient.firstName &&
      this.selectedClient.lastName
    ) {
      return Initials(
        this.selectedClient.firstName,
        this.selectedClient.lastName
      );
    } else {
      return "-";
    }
  }

  /**
   * computes the selected client full name
   */
  get selectedClientFullname() {
    if (
      this.selectedClient &&
      this.selectedClient.firstName &&
      this.selectedClient.lastName
    ) {
      return `${this.selectedClient.firstName} ${this.selectedClient.lastName}`;
    } else if (this.selectedClient && this.selectedClient.id) {
      return this.selectedClient.id;
    } else {
      return "-";
    }
  }

  /**
   * computes the selected client status
   */
  get selectedClientStatus() {
    return this.selectedClient && this.selectedClient.status
      ? this.selectedClient.status
      : null;
  }

  /**
   * extra validation for the client
   */
  get clientToSaveIsValid() {
    return (
      this.clientToSave &&
      ValidateEmail(this.clientToSave.email) &&
      ValidatePhone(this.clientToSave.tel)
    );
  }

  /**
   * extra validation for the appartment
   */
  get appartmentToSaveIsValid() {
    return true;
    // return ValidateEmail(this.appartmentToSave.selectedBy);
  }

  /**
   * extra validation for the added client
   */
  get clientToAddIsValid() {
    return (
      this.clientToAdd &&
      ValidateEmail(this.clientToAdd.email) &&
      ValidatePhone(this.clientToAdd.tel)
    );
  }

  get configLanguages() {
    if (Array.isArray(this.languages)) {
      return this.languages.map((lang) => {
        return convertLanguage(lang);
      });
    }
    return ["it-IT", "en-GB"];
  }
}

decorate(Store, {
  feVendorKey: [persist, observable],
  feKey: [persist, observable],
  loginProjectName: [persist, observable],
  breadCrumbs: [persist, observable],
  projectName: [persist, observable],
  assetsUrl: [persist, observable],
  route: [persist("list"), observable],
  currentNavTab: [persist, observable],
  loggedUser: observable,
  selectedClient: [persist("object"), observable],
  selectedAppartment: [persist("object"), observable],
  typologies: [persist("list"), observable],
  apartmentId: [persist, observable],
  clientId: [persist, observable],
  logo: [persist("list"), observable],
  externalPdf: [persist, observable],
  modifyingAppartment: [persist("object"), observable],
  removeAppartment: [persist("object"), observable],
  modifyingAppartmentOriginalState: [persist, observable],
  pdfToView: [persist, observable],
  projectId: [persist, observable],
  displaySection: [persist("list"), observable],
  vendorLogo: [persist("list"), observable],
  assetLogin: [persist("list"), observable],
  pageTitles: [persist("object"), observable],
  loggedToken: observable,
  systemLanguage: [persist, observable],
  enabledTools: [persist("object"), observable],

  openDrawer: observable,

  snackbarOpen: observable,
  snackbarMessage: observable,
  snackbarError: observable,
  longLoading: observable,
  clientToAdd: observable,
  appartmentModal: observable,
  addingAppartments: observable,
  removeAppartmentModal: observable,
  vendorEmail: observable,
  vendorPassword: observable,
  clientToSave: observable,
  appartmentToSave: observable,
  avatarMenu: observable,
  menuMobile: observable,
  errorModal: observable,
  realLocation: observable,
  globalEditing: observable,
  period_landing: observable,
  period_home: observable,
  period_fbAds: observable,
  period_googleAds: observable,

  google_analytics: observable,
  google_analytics_landing: observable,
  google_ads: observable,
  selling_analytics: observable,
  facebook_adsImpressions: observable,
  facebook_adsClick: observable,
  fbClickRaw: observable,
  fbImpressionRaw: observable,
  googleRaw: observable,
  googleRawModify: observable,
  fbClickRawModify: observable,
  fbImpressionRawModify: observable,
  def_period: observable,
  def_time_cache_reset: observable,
  loadingHome: observable,
  redirectionToSsoNeeded: observable,
  checkingJwt: observable,
  languages: observable,
  baseUrl: observable,

  setLoadingHome: action.bound,
  setGoogleAnalyticsLanding: action.bound,
  setDataDisplayPeriod: action.bound,
  setPdfToView: action.bound,
  setGlobalEditing: action.bound,
  setLongLoading: action.bound,
  setOrignalStateUpdateSelectedAppartment: action.bound,
  setRemoveAppartment: action.bound,
  setUpdateSelectedAppartment: action.bound,
  updateCurrentNavTab: action.bound,
  setRealLocation: action.bound,
  toggleMenuMobile: action.bound,
  closeMenuMobile: action.bound,
  setSelectedClient: action.bound,
  setAvatarMenu: action.bound,
  setLoggedUser: action.bound,
  setLoggedUserLang: action.bound,
  updateVendorEmail: action.bound,
  updateProjectName: action.bound,
  setApartmentId: action.bound,
  setSelectedAppartment: action.bound,
  setClientId: action.bound,
  logOut: action.bound,
  setAppartmentTypologies: action.bound,
  setAssets: action.bound,
  setAssetsByObject: action.bound,
  updateClientToSave: action.bound,
  updateClientToAdd: action.bound,
  updateAppartmentToSave: action.bound,
  setErrorModal: action.bound,
  setBreadCrumbs: action.bound,
  setAppartmentModal: action.bound,
  setRemoveModal: action.bound,
  updateGloballyClient: action.bound,
  setSystemLanguage: action.bound,
  setOpenDrawer: action.bound,
  forceLogout: action.bound,
  updateLoggedUser: action.bound,
  setCheckingJwt: action.bound,
  setBaseUrl: action.bound,

  initials: computed,
  clientInitials: computed,
  loggedUserFullname: computed,
  availableSizes: computed,
  selectedClientFullname: computed,
  selectedClientStatus: computed,
  clientToSaveIsValid: computed,
  appartmentToSaveIsValid: computed,
  clientToAddIsValid: computed,
  googleAnalyticsByDate: computed,
  facebookClicksByDate: computed,
  facebookImpressionsByDate: computed,
  configLanguages: computed,
});

export default Store;
