import React from "react";
import { useStore } from "../store/storeUtils";
import { useHistory } from "react-router-dom";
import { HomeRoute } from "routes/Routes";
import { observer } from "mobx-react";
import { autorun } from "mobx";
import useLogout from "customHooks/useLogout";
import { logger } from "@tecma/logs-fe";

const ClientComponent = observer(() => {
  const store = useStore();
  const { logOut } = useLogout({ logger });
  const history = useHistory();
  React.useEffect(() =>
    autorun(async () => {
      const isLoggedIn = store.projectId && store.loggedUser;
      if (isLoggedIn) {
        // l'utente è loggato, lo riporto alla pagina richiesta
        const params = new URLSearchParams(window.location.search);
        params.delete("hostname");
        history.push(`${HomeRoute.to}?${params}`);
      } else if (store.checkingJwt) {
        // controllo jwt in corso, aspetto
      } else {
        // Se non esiste il JWT l'utente non è loggato e lo rimando alla login
        // Creo l'url di reindirizzamento in base all'ambiente in cui ci troviamo
        // TODO sarebbe meglio avere un identificativo piuttosto che usare il nome...
        const targetTool = store?.enabledTools?.find(
          (e) => e.name === "BusinessPlatform"
        );
        let firstPart;
        let secondPart;
        let finalTargetTool = "";

        if (targetTool) {
          let targetToolSplitted = targetTool.url.split("?");

          //se la prima parte non contiene /login
          if (!targetToolSplitted[0].includes("/login")) {
            //aggiungo al fondo /login
            if (targetToolSplitted[0].endsWith("/")) {
              firstPart = targetToolSplitted[0].concat("login");
            } else {
              firstPart = targetToolSplitted[0].concat("/login");
            }
          } else {
            firstPart = targetToolSplitted[0];
          }

          //costruisco il backTo
          const backtoUrl = new URL(window.location.href);
          const params = new URLSearchParams(backtoUrl.search);
          params.delete("hostname");
          let backTo = `${window.location.origin}${window.location.pathname}?${params}`;
          backTo = backTo.replace("/login?", "");

          //se la seconda parte esiste
          if (targetToolSplitted[1]) {
            secondPart = targetToolSplitted[1].concat(
              `&backTo=${encodeURIComponent(backTo)}`
            );
            finalTargetTool = firstPart + "?" + secondPart;
          } else {
            finalTargetTool =
              firstPart + "?" + `backTo=${encodeURIComponent(backTo)}`;
          }
          try {
            let hasLoggedOut = await logOut();
            if (hasLoggedOut && hasLoggedOut.called && !hasLoggedOut.loading) {
              window.location.href = finalTargetTool;
            }
          } catch (e) {
            logger.error(e);
            window.location.href = finalTargetTool;
          }
        }

      }
    }, [store.projectId, store.loggedUser, store.checkingJwt])
  );

  return <></>;
});

export default ClientComponent;
