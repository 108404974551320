import { gql } from "@apollo/client";
import { pollInterval } from "client/ClientConstants";

const APPARTAMENT_TYPE_TO_RETRIVE = `
    id
    name
    visits
    rooms
    __typename
`;

const APPARTAMENT_PLAN_TO_RETRIVE = `
    id
    name
    visits
    typology {
      id
      name
    }
`;
const VENDOR_DATA_TO_RETRIEVE = `
      id
      firstName
      lastName
      email
      title
      avatar
      project_names
      session_id
`;
const PROJECT_DATA_TO_RETRIEVE = `
      id
      name
      logo
      vendorLogo
      assetLogin
      hostKey
      assetKey
      feVendorKey
      pdf
      assetsUrl
      displayName
      enabled_analytics
      pageTitles {
        followup
        tuning
      }
      tuningConfig
      baseurl
      enabledTools
`;
const USER_DATA_TO_RETRIEVE = `
  id
  firstName
  lastName
  email
  project_ids
  language
  role
  locale
`;
const GET_APPARTMENTS_PLAN = gql`
  query getAllPlans ($project_id :ID!) {
    getAllPlans (project_id :$project_id) {
      ${APPARTAMENT_PLAN_TO_RETRIVE}
    }
  }`;

const GET_APPARTMENTS_TYPE = gql`
  query getAllTypologies ($project_id :ID!) {
    getAllTypologies (project_id :$project_id) {
      ${APPARTAMENT_TYPE_TO_RETRIVE}
    }
  }`;

const LOGIN = gql`
  mutation loginTecma($input: LoginByProjectInput!) {
    loginByProject(input: $input) {
      token {
        accessToken
        refreshToken
        tokenType
        expiresIn
      }
      user {
        id
        firstName
        lastName
        createdOn
        email
      }
    }
  }
`;

const LOGIN_DEFAULT_OPTIONS = (email, password, project_id) => {
  return {
    variables: {
      email: email,
      password: password,
      project_id: project_id,
    },
  };
};

const REFRESH_TOKEN = gql`
  mutation refreshToken($input: RefreshTokenInput!) {
    refreshToken(input: $input) {
      tokenType
      accessToken
      refreshToken
      expiresIn
    }
  }
`;

const REFRESH_TOKEN_DEFAULT_OPTIONS = (refreshToken, email) => {
  return {
    variables: {
      input: {
        refreshToken,
        email,
      },
    },
  };
};

const GET_VENDOR = gql`
  query getVendorByEmail($email :String!) {
    getVendorByEmail(email: $email) {
      ${VENDOR_DATA_TO_RETRIEVE}
    }
  }`;

const GET_PROJECT_INFO = gql`
  query getProjectInfoByHost ($host: String!, $url: String) {
    getProjectInfoByHost (host: $host, url: $url) {
      ${PROJECT_DATA_TO_RETRIEVE}
    }
  }`;

const GET_ALL_APPARTMENTS_DEFAULT_OPTIONS = (pId) => {
  return {
    variables: {
      project_id: pId,
    },
    pollInterval,
  };
};
const GET_PROJECT_INFO_DEFAULT_OPTIONS = (hostname) => {
  return {
    variables: {
      host: hostname,
      url: window.location.href,
    },
  };
};

const UPDATE_USER = gql`
  mutation updateUsers( $id: ID!, $input: UserInput!) {
    updateUsers( id: $id, input: $input) {
      ${USER_DATA_TO_RETRIEVE}
    }
  }
`;

const UPDATE_USER_DEFAULT_OPTIONS = (id, input) => {
  return {
    variables: {
      id: id,
      input: input,
    },
  };
};

const GET_USER_INFO = gql`
  query getUserByJWT($project_id: ID!) {
    getUserByJWT(project_id: $project_id) {
      id
      firstName
      lastName
      email
      createdOn
      client
      project_ids
      language
      role
      locale
    }
  }
`;

const GET_USER_INFO_DEFAULT_OPTIONS = (pId) => {
  return {
    variables: {
      project_id: pId,
    },
  };
};

const GET_USER_INFO_DEFAULT_OPTIONS_V2 = (pId) => {
  return {
    variables: {
      project_id: pId,
    },
    fetchPolicy: "network-only",
  };
};

const MUTATION_LOGOUT = gql`
  mutation logout {
    logout
  }
`;

const Client = {
  GET_APPARTMENTS_PLAN,
  GET_APPARTMENTS_TYPE,
  GET_PROJECT_INFO,
  GET_ALL_APPARTMENTS_DEFAULT_OPTIONS,
  GET_PROJECT_INFO_DEFAULT_OPTIONS,
  LOGIN,
  LOGIN_DEFAULT_OPTIONS,
  UPDATE_USER,
  UPDATE_USER_DEFAULT_OPTIONS,
  GET_USER_INFO,
  GET_USER_INFO_DEFAULT_OPTIONS,
  GET_USER_INFO_DEFAULT_OPTIONS_V2,
  REFRESH_TOKEN,
  REFRESH_TOKEN_DEFAULT_OPTIONS,
  MUTATION_LOGOUT,
};

export default Client;
