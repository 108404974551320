import React, { useEffect } from "react";
import { useStore } from "store/storeUtils.js";
import { useLazyQuery } from "@apollo/client";
import Client from "client/Client";

const JwtChecker = () => {
  const store = useStore();
  const [loadUserInfo, userInfo] = useLazyQuery(
    Client.GET_USER_INFO,
    Client.GET_USER_INFO_DEFAULT_OPTIONS_V2(store.projectId)
  );

  useEffect(() => {
    if (userInfo && !userInfo.loading && userInfo.called && userInfo.data) {
      store.setLoggedUser(userInfo.data.getUserByJWT);
      store.setCheckingJwt(false);
    }
  }, [userInfo, !userInfo.loading, userInfo.called, userInfo.data]);

  function handleVisibilityChange() {
    if (!document.hidden) {
      store.setCheckingJwt(true);
      loadUserInfo();
    }
  }

  React.useEffect(() => {
    window.addEventListener("visibilitychange", handleVisibilityChange);
    return () =>
      window.removeEventListener("visibilitychange", handleVisibilityChange);
  }, []);

  return <></>;
};

export default JwtChecker;
