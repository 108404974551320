import colors from "styles/colors.scss";

const periodButtonLabel = [
  { name: "all", label: "Mese Corrente" },
  { name: "newPeriod", label: "Settimana Corrente" },
  { name: "newPeriod", label: "Settimana Passata" },
];

const verticalGraphDataImpression = [
  { label: "10-20", age: 100 },
  { label: "20-30", age: 210 },
  { label: "30-40", age: 234 },
  { label: "40-50", age: 387 },
  { label: "50-60", age: 237 },
];

const lineLabels = ["utenti", "total"];

const defaultColors = [
  colors.VERDE,
  colors.AZZURRO,
  colors.GIALLO,
  colors.ARANCIO,
  colors.VIOLA,
  colors.ROSSO,
  colors.VERDE,
  colors.GIALLO,
  colors.AZZURRO,
  colors.ARANCIO,
  colors.VIOLA,
  colors.ROSSO,
  colors.GIALLO,
  colors.VIOLA,
  colors.VERDE,
  colors.AZZURRO,
  colors.ROSSO,
  colors.ARANCIO,
  colors.VIOLA,
  colors.ROSSO,
  colors.VIOLA,
  colors.VERDE,
  colors.AZZURRO,
  colors.ROSSO,
];
const availableStatus = [
  "bilocale",
  "click",
  "duplex",
  "trilocale",
  "pentalocale duplex",
  "quadrilocale",
  "Tablet",
  "Desktop",
  "Mobile",
  "utenti",
  "utenti nuovi",
  "Refaral",
  "Direct",
  "Social",
  "Organic Search",
  "Other",
];
const availableSizes = [
  "Bilocale",
  "Trilocale",
  "Quadrilocale",
  "Pentalocale Duplex",
];
const shortNames = {
  monolocale: "Mono",
  bilocale: "Bilo",
  trilocale: "Trilo",
  quadrilocale: "Quadri",
  pentalocale: "Penta",
  penthouse: "Pent",
  "pentalocale duplex": "Penta",
  duplex: "Duplex",
};
const keyTofilter = "status";
const borderColor = "rgba(0,0,0,0)";

const ChartConstants = {
  defaultColors,
  availableStatus,
  availableSizes,
  shortNames,
  keyTofilter,
  borderColor,
  verticalGraphDataImpression,
  lineLabels,
  periodButtonLabel,
};

export default ChartConstants;
