import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';


const API = (store, logger) => {

  const errorLink = onError(({ networkError, graphQLErrors, operation, response }) => {
    if (networkError?.result?.errors[0]?.extensions?.code === 'UNAUTHENTICATED' || networkError?.statusCode === 401 || graphQLErrors[0]?.extensions?.response?.status === 403) {
      logger.error(`Apollo client network error code: ${networkError?.statusCode}`);
      store.forceLogout(true); // prima passa per il componente "LogoutListener" per la pulizia dei cookies e poi avverrà il reindirizzamento
    } else if (operation?.operationName === 'getUserByJWT' && response?.data?.getUserByJWT === null && response?.errors?.length > 0) {
      logger.error(`getUserByJWT: user not found`);
      store.forceLogout(true); // prima passa per il componente "LogoutListener" per la pulizia dei cookies e poi avverrà il reindirizzamento
    }
  });

  const httpLink = from([
    errorLink,
    new HttpLink({
      credentials: 'include',
      uri: process.env.REACT_APP_APOLLO_URI,
    }),
  ]);

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
      },
    };
  });

  return new ApolloClient({
    credentials: 'include',
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({}),
  });
};

export default API;
