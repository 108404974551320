const setChartDataHome = (web = [], landing = []) => {
  let chartData = [];

  landing = Array.from(
    landing.reduce(
      (m, { name, utenti }) => m.set(name, (+m.get(name) || 0) + utenti),
      new Map()
    ),
    ([name, utenti]) => ({ name, utenti })
  ).map((el) => ({
    name: el.name,
    landing: el.utenti,
  }));

  chartData = mergeData(web, landing).map((el) => ({
    name: el.name,
    landing: el.landing | 0,
    website: el.utenti,
    total: (el.landing | 0) + (el.utenti | 0),
  }));

  return chartData;
};

const setChartDataLanding = (landing = [], web = []) => {
  const separatedLandings = [];
  const landingLabels = new Set();
  landing.map((el) => {
    const index = separatedLandings.findIndex((e) => el.name === e.name);
    const label = el.landing.substring(1, el.landing.length - 1);
    landingLabels.add(label);
    if (index > -1) {
      separatedLandings[index][label] += el.utenti;
    } else {
      const newObj = { name: el.name };
      newObj[label] = el.utenti;
      separatedLandings.push(newObj);
    }
  });

  return mergeData(separatedLandings, web).map((el) => {
    const { name, ...other } = el;
    const total = Object.keys(other).reduce(
      (total, key) => total + parseInt(other[key] || 0),
      0
    );
    Array.from(landingLabels).map((label) => {
      if (!(label in el)) {
        el[label] = 0;
      }
    });
    return { ...el, total };
  });
};

const mergeData = (a1, a2) =>
  a1.map((itm) => {
    return {
      ...a2.find((item) => item.name === itm.name && item),
      ...itm,
    };
  });

const setCustomLabels = (labels, data) => {
  const uniqueLabels = [...new Set(data.flatMap((el) => Object.keys(el)))];
  const landingLabels = uniqueLabels.filter(
    (el) => el !== "name" && el !== "website" && el !== "total"
  );

  return [...landingLabels, ...labels];
};

export { setChartDataHome, setChartDataLanding, setCustomLabels };
