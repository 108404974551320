import isProd from "./isProd";

/**
 * gets actual host
 * @todo remove this utility
 */
const DetectUrl = () => {
  let hostname =
    window.location.hostname.indexOf("www.") >= 0
      ? window.location.hostname
      : "www." + window.location.hostname;

  if (isProd()) {
    const hostPath = window.location.href;
    if (hostPath !== localStorage.getItem("tuning_hostname")) {
      localStorage.clear();
      localStorage.setItem("tuning_hostname", hostPath);
    }
    return hostname;
  }

  const urlParams = new URLSearchParams(window.location.search);
  const hostnameParam = urlParams.get("hostname");
  if (hostnameParam && hostnameParam.length > 0 && hostnameParam !== "") {
    localStorage.clear();
    localStorage.setItem("tuning_dev_hostname", hostnameParam);
  }
  let tuning_dev_hostname = localStorage.getItem("tuning_dev_hostname");
  if (
    tuning_dev_hostname &&
    tuning_dev_hostname.length > 0 &&
    tuning_dev_hostname !== ""
  ) {
    return tuning_dev_hostname;
  }

  hostname = hostname || process.env.REACT_APP_HOST;

  return hostname;
};

export default DetectUrl;
