import { useMutation } from '@apollo/client';
import Client from 'client/Client';

const useLogout = ({logger}) => {
  const [logoutMutation, logoutMutationInfo] = useMutation(Client.MUTATION_LOGOUT);

  const logOut = async () => {
    if (logoutMutationInfo && logoutMutationInfo.called) {
      return logoutMutationInfo;
    } else {
      try {
        await logoutMutation();
        return logoutMutationInfo;
      } catch (e) {
        logger.error('logout on server error');
        throw e;
      }
    }
  };
  return { logOut };
};

export default useLogout;