import React from 'react';
import Home from 'pages/Home';

import Landig from 'images/assets/landing-logo.svg';
import Analytics from 'images/assets/analytics.svg';
import Facebook from 'images/assets/facebook-logo.svg';
import Google from 'images/assets/adwords-logo.svg';
import Project from 'images/assets/selling-logo.svg';
import { Trans } from '@tecma/i18n';

const LandingAnalytics = React.lazy(() => import('pages/LandingAnalytics'));
const FacebookAds = React.lazy(() => import('pages/FacebookAds'));
const GoogleAds = React.lazy(() => import('pages/GoogleAds'));
const ProjectAnalytics = React.lazy(() => import('pages/ProjectAnalytics'));

export const HomeRoute = {
  id: 'web',
  order: 0,
  exact: true,
  to: '/',
  title: (
    <Trans i18nKey={'dashboard.webAnalytics' } />
  ),
  navbarTitle: (
    <Trans i18nKey={'dashboard.webAnalytics' } />
  ),
  icon: Analytics,
  navbar: true,
  goBack: false,
  component: Home,
};

export const webAnalyticsRoute = {
  id: 'web-analitics',
  order: 1,
  exact: true,
  to: '/web-analitics',
  title: (
    <Trans i18nKey={'dashboard.webAnalytics' } />
  ),
  navbarTitle: (
    <Trans i18nKey={'dashboard.webAnalytics'}/>
  ),
  icon: Analytics,
  navbar: true,
  goBack: false,
  component: Home,
};

export const landingRoute = {
  id: 'landing',
  order: 2,
  exact: true,
  to: '/landing',
  title: (
    <Trans
      i18nKey={'dashboard.landingAnalytics'}
     />
  ),
  navbarTitle: (
    <Trans
      i18nKey={'dashboard.landingAnalytics'}
     />
  ),
  icon: Landig,
  navbar: true,
  goBack: false,
  component: LandingAnalytics,
};

export const facebookRoute = {
  id: 'fbAds',
  order: 3,
  exact: true,
  to: '/facebook-ads',
  title: (
    <Trans i18nKey={'dashboard.facebookAds' } />
  ),
  navbarTitle: (
    <Trans i18nKey={'dashboard.facebookAds' } />
  ),
  icon: Facebook,
  navbar: true,
  goBack: false,
  component: FacebookAds,
};

export const googleRoute = {
  id: 'gAds',
  order: 4,
  exact: true,
  to: '/google-ads',
  title: (
    <Trans i18nKey={'dashboard.googleAds' }/>
  ),
  navbarTitle: (
    <Trans i18nKey={'dashboard.googleAds' } />
  ),
  icon: Google,
  navbar: true,
  goBack: false,
  component: GoogleAds,
};

export const sellingRoute = {
  id: 'project',
  order: 5,
  exact: true,
  to: '/project-analytics',
  title: (
    <Trans
      i18nKey={'dashboard.projectAnalytics'}

    />
    ),
    navbarTitle: (
      <Trans
        i18nKey={'dashboard.projectAnalytics'}
  
      />
 
  ),
  icon: Project,
  navbar: true,
  goBack: false,
  component: ProjectAnalytics,
};
const RouteConsts = [
  HomeRoute,
  landingRoute,
  facebookRoute,
  googleRoute,
  sellingRoute,
];

export default RouteConsts;
