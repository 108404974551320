import React from "react";
import TuningCard from "components/common/TuningCard";
import Grid from "@material-ui/core/Grid";
import TuningLoader from "components/common/TuningLoader";
import HorizontalLineChart from "components/charts/HorizontalLineChart";
import DoughnutReChart from "components/charts/DoughnutReChart";
import HorizontalBarReChart from "components/charts/HorizontalBarReChart";
import ContentWrapper from "components/charts/ContentWrapper";
import { useStore } from "store/storeUtils.js";
import { observer } from "mobx-react";
import {
  googleAnalyticsViewFormat,
  googleAnalyticsFormat,
} from "utils/formatResponse";
import { Trans } from "@tecma/i18n";

import moment from "moment";

import { setChartDataHome, setCustomLabels } from "utils/ChartDataFormatter";

import CheckValidStatusRest from "utils/CheckValidStatusRest";

import colors from "styles/colors.scss";
import { toJS } from "mobx";

import TuningSvgLoader from "components/common/TuningSvgLoader";

import Placeholder from "images/assets/placeholder.svg";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { logger } from "@tecma/logs-fe";
import TuningDateRange from "components/common/TuningDateRange";
import TuningScreenshotButton from "components/common/TuningScreenshotButton";

/**
 * Home page
 * Contiene le tabelle clienti e appartamenti, oltre ai grafici
 * @component
 * @see LineChart
 * @see ListaClienti
 * @see ListaAppartamenti
 * @see DoughnutReChart
 * @see HorizontalBarReChart
 * @see VerticalBarReChart
 * @see ContentWrapper
 */
const Home = observer((props) => {
  const store = useStore();

  React.useEffect(() => {
    const project_id = store.projectId;
    const google_url = process.env.REACT_APP_GOOGLE_ANALYTICS_ADS_URL;
    const google_url_landing =
      process.env.REACT_APP_GOOGLE_ANALYTICS_LANDING_ADS_URL;
    const hours = store.def_time_cache_reset;
    const now = new Date();
    const setupTime = window.localStorage.getItem("setupGoogleTime");

    store.setHomeStartPeriod(moment(now).subtract("week", 1), "newPeriod");
    store.setHomeEndPeriod(moment(now), "newPeriod");
    if (
      window.localStorage.getItem("googleResponse") &&
      !isNaN(setupTime) &&
      now - setupTime < hours * 60 * 60 * 1000
    ) {
      const data = JSON.parse(window.localStorage.getItem("googleResponse"));
      store.setGoogleDataRaw(data);
      store.setGoogleAnalytics(
        googleAnalyticsViewFormat(store.googleAnalyticsByDate)
      );
      store.setDefineMaxMin();
      setTimeout(() => {
        store.setLoadingHome(false);
      }, 0);
    } else if (isNaN(setupTime) || now - setupTime >= hours * 60 * 60 * 1000) {
      const body = new FormData();
      body.append("project_id", project_id);
      if (moment(now).hour() >= 5) {
        body.append(
          "date_stop",
          moment(now).subtract("day", 1).format("YYYY-MM-DD")
        );
      } else {
        body.append(
          "date_stop",
          moment(now).subtract("day", 2).format("YYYY-MM-DD")
        );
      }
      body.append(
        "date_start",
        moment(now).subtract("year", 2).format("YYYY-MM-DD")
      );

      fetch(google_url, {
        credentials: "include",
        method: "POST",
        body: body,
      })
        .then((response) => CheckValidStatusRest(response, store))
        .then((data) => {
          store.setGoogleDataRaw([data]);
          store.setGoogleAnalytics(
            googleAnalyticsViewFormat(store.googleAnalyticsByDate)
          );
          store.setDefineMaxMin();
          window.localStorage.setItem("googleResponse", JSON.stringify([data]));
          setTimeout(() => {
            store.setLoadingHome(false);
          }, 0);
        })
        .catch((e) => {
          logger.error(e);
        });
      window.localStorage.setItem("setupGoogleTime", now.getTime());
    }
    if (
      window.localStorage.getItem("googleResponseLanding") &&
      !isNaN(setupTime) &&
      now - setupTime < hours * 60 * 60 * 1000
    ) {
      const data = JSON.parse(
        window.localStorage.getItem("googleResponseLanding")
      );
      store.setGoogleDataRawLanding(data);
      store.setGoogleAnalyticsLanding(
        googleAnalyticsFormat(store.googleAnalyticsByDateLanding)
      );
      store.setDefineMaxMinLanding();
      //setTimeout(()=>{store.setLoadingHome(false);}, 0)
    } else if (isNaN(setupTime) || now - setupTime >= hours * 60 * 60 * 1000) {
      const body = new FormData();
      body.append("project_id", project_id);
      if (moment(now).hour() >= 5) {
        body.append(
          "date_stop",
          moment(now).subtract("day", 1).format("YYYY-MM-DD")
        );
      } else {
        body.append(
          "date_stop",
          moment(now).subtract("day", 2).format("YYYY-MM-DD")
        );
      }
      body.append(
        "date_start",
        moment(now).subtract("year", 2).format("YYYY-MM-DD")
      );

      fetch(google_url_landing, {
        credentials: "include",
        method: "POST",
        body: body,
      })
        .then((response) => CheckValidStatusRest(response, store))
        .then((data) => {
          store.setGoogleDataRawLanding([data]);
          store.setGoogleAnalyticsLanding(
            googleAnalyticsFormat(store.googleAnalyticsByDateLanding)
          );
          store.setDefineMaxMinLanding();
          window.localStorage.setItem(
            "googleResponseLanding",
            JSON.stringify([data])
          );
        })
        .catch((e) => {
          logger.error(e);
        });
      window.localStorage.setItem("setupGoogleTime", now.getTime());
    }
  }, []);

  const updateEndDate = (newDate) => {
    store.setNewPeriodGoogle();
    store.setHomeEndPeriod(newDate, "newPeriod");
    store.setGoogleAnalytics(
      googleAnalyticsViewFormat(store.googleAnalyticsByDate)
    );
    store.setGoogleAnalyticsLanding(
      googleAnalyticsFormat(store.googleAnalyticsByDateLanding)
    );
  };
  const updateStartDate = (newDate) => {
    store.setHomeStartPeriod(newDate, "newPeriod");
  };

  const chartData = setChartDataHome(
    store.google_analytics.all.dailyChartChart,
    store.google_analytics_landing.all.dailyChartChart
  );
  const customLabels = setCustomLabels(["website", "total"], chartData);

  let analyticsData = [];

  if (
    store.google_analytics.userData &&
    store.google_analytics.userData.length > 0 &&
    store.google_analytics.dataElement &&
    store.google_analytics.dataElement.length > 0
  ) {
    for (let i = 0; i < store.google_analytics.userData.length; i++) {
      analyticsData.push(toJS(store.google_analytics.userData[i]));
      let k = i * 2;
      analyticsData.push(toJS(store.google_analytics.dataElement[k]));
      analyticsData.push(toJS(store.google_analytics.dataElement[k + 1]));
    }
  }

  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });

  return (
    <div className="main-container">
      <TuningScreenshotButton
        screenshotClassName="main-container"
        screenshotRef={props.screenshotRef}
      />
      {store.period_home.max &&
        store.period_home.min &&
        store.period_home.start && (
          <div style={{ display: "flex" }}>
            <TuningDateRange
              start={store.period_home.start.toDate()}
              end={store.period_home.end.toDate()}
              max={store.period_home.max.toDate()}
              min={store.period_home.min.toDate()}
              updateStart={updateStartDate}
              updateEnd={updateEndDate}
            />
          </div>
        )}

      <Grid container justify="center">
        {matchesSm ? (
          <Grid item xs={10} sm={10} md={12} lg={6} className="border-grid">
            <TuningCard style={{ overflow: "visible", padding: "0px" }}>
              {store.loadingHome && (
                <TuningLoader loading={store.loadingHome} hasBackdrop={false} />
              )}
              <Grid container justify="center" className="full-height">
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  className="border-right-line"
                >
                  {store.google_analytics.all.daviceChart &&
                  store.google_analytics.all.daviceChart.length ? (
                    <>
                      <div className="elements-spacing semi-bold small-font-14 title-padding">
                        <Trans i18nKey={"home.usersOrigin"} />
                      </div>
                      <DoughnutReChart
                        data={store.google_analytics.all.daviceChart}
                        radius={75}
                        height={250}
                        availableColors={[
                          colors.LAVENDER,
                          colors.ARANCIO,
                          colors.PURPLE,
                        ]}
                      />
                    </>
                  ) : (
                    <div className="flex-column justify-center flex-centered full-height">
                      <div className="regular small-font-14 placeholder-components title-padding">
                        <TuningSvgLoader data={Placeholder} alt="Placeholder" />
                        <div style={{ padding: "30px" }}>
                          <Trans i18nKey={"home.noDataTimeInterval"} />
                        </div>
                      </div>
                    </div>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  className="padding-top-lg"
                >
                  {store.google_analytics.all.userChart &&
                  store.google_analytics.all.userChart.length ? (
                    <>
                      <div className="elements-spacing semi-bold small-font-14 title-padding">
                        <Trans i18nKey={"home.newUsers"} />
                      </div>
                      <DoughnutReChart
                        data={store.google_analytics.all.userChart}
                        radius={75}
                        height={250}
                        availableColors={[colors.VIOLA, colors.GIALLO]}
                      />
                    </>
                  ) : (
                    <div className="flex-column justify-center flex-centered full-height">
                      <div className="regular small-font-14 placeholder-components title-padding">
                        <TuningSvgLoader data={Placeholder} alt="Placeholder" />
                        <div style={{ padding: "30px" }}>
                          <Trans i18nKey={"home.noDataTimeInterval"} />
                        </div>
                      </div>
                    </div>
                  )}
                </Grid>
              </Grid>
            </TuningCard>
          </Grid>
        ) : (
          <Grid container justify="center">
            <Grid item xs={10} className="border-grid">
              <TuningCard style={{ overflow: "visible", padding: "0px" }}>
                {store.loadingHome && (
                  <TuningLoader
                    loading={store.loadingHome}
                    hasBackdrop={false}
                  />
                )}
                {store.google_analytics.all.daviceChart &&
                store.google_analytics.all.daviceChart.length ? (
                  <>
                    <div className="elements-spacing semi-bold small-font-14 title-padding">
                      <Trans i18nKey={"home.usersOrigin"} />
                    </div>
                    <DoughnutReChart
                      data={store.google_analytics.all.daviceChart}
                      radius={75}
                      height={250}
                      availableColors={[
                        colors.ROSSO,
                        colors.VERDE,
                        colors.GIALLO,
                      ]}
                    />
                  </>
                ) : (
                  <div className="flex-column justify-center flex-centered full-height">
                    <div className="regular small-font-14 placeholder-components title-padding">
                      <TuningSvgLoader data={Placeholder} alt="Placeholder" />
                      <div style={{ padding: "30px" }}>
                        <Trans i18nKey={"home.noDataTimeInterval"} />
                      </div>
                    </div>
                  </div>
                )}
              </TuningCard>
            </Grid>
            <Grid item xs={10} className="border-grid">
              <TuningCard style={{ overflow: "visible", padding: "0px" }}>
                {store.loadingHome && (
                  <TuningLoader
                    loading={store.loadingHome}
                    hasBackdrop={false}
                  />
                )}
                {store.google_analytics.all.userChart &&
                store.google_analytics.all.userChart.length ? (
                  <>
                    <div className="elements-spacing semi-bold small-font-14 title-padding">
                      <Trans i18nKey={"home.newUsers"} />
                    </div>
                    <DoughnutReChart
                      data={store.google_analytics.all.userChart}
                      radius={75}
                      height={250}
                      availableColors={[colors.VERDE, colors.GIALLO]}
                    />
                  </>
                ) : (
                  <div className="flex-column justify-center flex-centered full-height">
                    <div className="regular small-font-14 placeholder-components title-padding">
                      <TuningSvgLoader data={Placeholder} alt="Placeholder" />
                      <div style={{ padding: "30px" }}>
                        <Trans i18nKey={"home.noDataTimeInterval"} />
                      </div>
                    </div>
                  </div>
                )}
              </TuningCard>
            </Grid>
          </Grid>
        )}
        <Grid item xs={10} sm={10} md={12} lg={6} className="border-grid">
          <TuningCard style={{ overflow: "visible", padding: "0px" }}>
            {store.loadingHome && (
              <TuningLoader loading={store.loadingHome} hasBackdrop={false} />
            )}
            <Grid container justify="center" className="full-height">
              {analyticsData.length > 0 &&
                analyticsData.map((stat, i) => (
                  <Grid
                    key={i}
                    item
                    xs={4}
                    className={
                      i < 6
                        ? i % 3 !== 2
                          ? "table-cell-grid-right table-cell-grid-bottom"
                          : "table-cell-grid-bottom"
                        : i % 3 !== 2 && "table-cell-grid-right"
                    }
                  >
                    <ContentWrapper
                      text={stat.text}
                      dataNumber={stat.dataNumber}
                      type={stat.type}
                    />
                  </Grid>
                ))}
            </Grid>
          </TuningCard>
        </Grid>
        <Grid item xs={10} sm={10} md={12} lg={6} className="border-grid">
          <TuningCard style={{ overflow: "visible", padding: "0px" }}>
            {store.loadingHome && (
              <TuningLoader loading={store.loadingHome} hasBackdrop={false} />
            )}
            {store.google_analytics.all.dailyChartChart &&
            store.google_analytics.all.dailyChartChart.length ? (
              <>
                <div className="elements-spacing semi-bold small-font-14 title-padding">
                  <Trans i18nKey={"home.dailyUsers"} />
                </div>
                <div className="elements-spacing-graph">
                  <HorizontalLineChart data={chartData} labels={customLabels} />
                </div>
              </>
            ) : (
              <div className="flex-column justify-center flex-centered full-height">
                <div className="regular small-font-14 placeholder-components title-padding">
                  <div style={{ paddingBottom: "50px" }}>
                    <Trans i18nKey={"home.noDataTimeInterval"} />
                  </div>
                </div>
              </div>
            )}
          </TuningCard>
        </Grid>

        <Grid item xs={10} sm={10} md={12} lg={6} className="border-grid">
          <TuningCard style={{ overflowY: "auto", padding: "0px" }}>
            {store.loadingHome && (
              <TuningLoader loading={store.loadingHome} hasBackdrop={false} />
            )}
            {store.google_analytics.all.dailyChartChart &&
            store.google_analytics.all.dailyChartChart.length ? (
              <>
                <div className="elements-spacing semi-bold small-font-14">
                  <Trans i18nKey={"home.usersSource"} />
                </div>
                <div className="elements-spacing">
                  <HorizontalBarReChart
                    data={store.google_analytics.all.sourceChart}
                    availableColors={[
                      colors.AZZURRO_SCURO,
                      colors.PURPLE,
                      colors.AZZURRO,
                      colors.VIOLA_CHIARO,
                      colors.LAVENDER,
                      colors.VIOLA,
                      colors.ROSSO,
                      colors.ORGANIC,
                    ]}
                  />
                </div>
              </>
            ) : (
              <div className="flex-column justify-center flex-centered full-height">
                <div className="regular small-font-14 placeholder-components title-padding">
                  <div style={{ paddingBottom: "50px" }}>
                    <Trans i18nKey={"home.noDataTimeInterval"} />
                  </div>
                </div>
              </div>
            )}
          </TuningCard>
        </Grid>
      </Grid>
    </div>
  );
});

export default Home;
