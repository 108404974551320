import colors from './colors.scss';
import variables from './variables.scss';
import 'styles/main.scss';

export const CrmMUITheme = {
  typography: {
    fontFamily: variables.DEFAULT_FONT,
    fontWeight: variables.LIGHT,
  },
  overrides: {
    MuiPaper: {
      root: {
        color: colors.DEFAULT_FONT_COLOR,
        borderRadius: '0px!important',
        zIndex: '0',
        border: '1px solid #E8E9EB',
      },
      elevation2: {
        boxShadow: 'none',
      },
      elevation4: {
        boxShadow: 'none',
      },
      elevation8: {
        boxShadow: 'none',
      },
    },
    MuiButton: {
      root: {
        color: colors.DEFAULT_FONT_COLOR,
      },
    },
    MuiDrawer: {
      paper: {
        borderRight: 'none!important',
        backgroundColor: '#E8E9EB',
        zIndex: '3',
      },
      paperAnchorDockedLeft: {
        borderRight: 'none!important',
        backgroundColor: '#E8E9EB',
        zIndex: '3',
      },
      paperAnchorRight: {
        borderLeft: 'none!important',
        backgroundColor: '#E8E9EB',
        zIndex: '4',
      },
    },
    MuiListItem: {
      root: {
        height: '48px',
        width: '100%!important',
        marginTop: '-1px',
        position: 'relative',
        borderBottom: '1px solid #CACBD3',
        padding: '0px !important',
        '&:hover': {
          backgroundColor: '#CACBD3!important',
        },
        '&$selected': {
          borderBottom: '1px solid #f5f5fc',
          borderTop: '1px solid #f5f5fc',
          zIndex: '2',
          backgroundColor: colors.BACKGROUND_PAGE,
          '&:active':{
            backgroundColor: colors.BACKGROUND_PAGE,
          },
          '&:hover': {
            borderBottom: '1px solid #CACBD3',
            borderTop: '1px solid #CACBD3',
          },
          '& div.navbar-image-size': {
            borderLeft: '2px solid #6BC1B4 !important',
            paddingLeft: '6px!important',
          },
        },
      },
    },
    MuiMenu: {
      paper: {
        width: '100px',
        borderRadius: '0px',
      },
      list: {
        padding: '0px!important',
        width: '100%!important',
      },
    },
    MuiMenuItem: {
      root: {
        paddingLeft: '5px!important',
        width: '100%',
        height: '32px',
        backgroundColor: '#E8E9EB',
        fontSize: '12px',
        border: '0px',
        '&$selected': {
          backgroundColor: '#1E201F',
          color: 'white',
          border: '0px',
          width: '100%',
        },
        '&:hover': {
          backgroundColor: '#CACBD3!important',
        },
        '&:active': {
          backgroundColor: '#1E201F!important',
          color: 'white',
        },
      },
    },
    MuiToggleButtonGroup: {
      root: {
        backgroundColor: '#E8E9EB',
        border: 'none',
        borderRadius: '0px',
      },
    },
    MuiToggleButton: {
      root: {
        border: 'none',
        marginRight: '5px',
        color: '#1E201F',
        fontSize: '18px',
        height: '18px',
        borderRadius: '0px',
        fontWeight: '600',

        '&$selected': {
          backgroundColor: '#E8E9EB',
          textDecoration: 'underline',
          color: '#1E201F',
          '&:hover': {
            backgroundColor: '#E8E9EB',
            textDecoration: 'underline',
          },
        },
        '&:hover': {
          backgroundColor: '#E8E9EB',
        },
      },
    },
    MuiTableCell: {
      root: {
        borderBottomWidth: '0px!important',
        borderTopWidth: '0px!important',
        fontSize: '14px',
      },
      body: {
        fontWeight: '600',
      },
      head: {
        textTransform: 'uppercase',
        fontWeight: '700',
        backgroundColor: '#E8E9EB!important',
        fontSize: '16px',
        whiteSpace: 'nowrap',
      },
      footer: {
        width: 'fit-content!important',
      },
    },
    MuiTableRow: {
      footer: {
        backgroundColor: 'rgba(232, 233, 235, 0.38)',
      },
    },
    MuiTablePagination: {
      toolbar: {
        display: 'flex',
        flexFlow: 'row-reverse',
        backgroundColor: 'transparent!important',
        flexWrap: 'wrap',
        paddingTop: '10px!important',
      },
    },
    MuiToolbar: {
      root: {
        backgroundColor: 'rgba(232, 233, 235, 0.38)',
        paddingLeft: '0px!important',
        paddingRight: '0px!important',
        flexWrap: 'wrap',
      },
      gutters: {
        paddingLeft: '0px!important',
        paddingBottom: '10px',
        paddingRight: '0px!important',
        flexWrap: 'wrap',
      },
    },
    MuiSelect: {
      root: {
        border: '1px solid #E8E9EB!important',
        padding: '8px 25px 8px 5px!important',
        color: '#1E201F',
      },
    },
    MuiTypography: {
      caption: {
        color: '#1E201F',
        fontSize: '14px',
      },
    },
    MuiIconButton: {
      root: {
        color: '#959595',
      },
    },
    MuiInput: {
      input: {
        fontSize: '14px',
        color: '#959595',
      },
      underline: {
        '&:before': {
          borderBottom: '2px solid #E8E9EB',
        },
        '&:after': {
          borderBottom: '2px solid #6BC1B4',
        },
        '&&&&:hover:before': {
          borderBottom: '2px solid #CACBD3',
        },
      },
    },
    MuiTableSortLabel: {
      root: {
        display: 'flex',
        justifyContent: 'space-between',
      },
      icon: {
        height: '25px',
        width: '25px',
      },
    },
    MuiFormControl: {
      root: {
        padding: '0px!important',
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: '2px',
        fontSize: '14px',
      },
    },
  },
  palette: {
    primary: {
      main: colors.COLOR_PRIMARY,
    },
    secondary: {
      main: colors.COLOR_SECONDARY,
    },
  },
};
